import { isEqual } from 'lodash'

export function keysOf<T>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[]
}

export function entriesOf<TK extends string | number, TV>(
  obj: { [key in TK]?: TV },
): [TK, TV][] {
  const entries = Object.entries(obj)
  return entries as [TK, TV][]
}

export function valueOfEnumKey<T>(enumType: any, key: string): T {
  return enumType[key as keyof T]
}

export function differentKeys<T>(from: T, to: T): (keyof T)[] {
  return keysOf(from).filter((key) => !isEqual(from[key], to[key]))
}
