import {
  IonButton, IonContent, IonIcon, IonPage,
} from '@ionic/react'
import { close } from 'ionicons/icons'
import React, { useEffect } from 'react'
import ClickableDiv from '../../components/clickable/ClickableDiv'
import StringRenderer from '../../components/StringRenderer'
import useLiveData from '../../liveData/useLiveData'
import useLocalization from '../../plugins/localization/useLocalization'
import DefaultPromoIntroViewModel from '../../viewModels/promo/DefaultPromoIntroViewModel'
import BasePromoView from './BasePromoView'
import './DefaultPromoIntroPage.scss'

export type DefaultPromoIntroProps = Readonly<{
  id: string
  className?: string
  viewModel: DefaultPromoIntroViewModel
  titleImage: string
}>

const DefaultPromoIntroPage: React.FC<DefaultPromoIntroProps> = ({
  id, className, viewModel, titleImage,
}: DefaultPromoIntroProps) => {
  const { strings } = useLocalization()

  const [{ disclaimer, period, value }] = useLiveData(viewModel.messages)

  useEffect(() => {
    viewModel.init()
    return viewModel.deinit
  }, [viewModel])

  return (
    <IonPage id={id} className={className}>
      <IonContent className="promo-intro-container">
        <IonButton onClick={viewModel.close} color="transparent" className="promo-intro__close">
          <IonIcon icon={close} />
        </IonButton>
        <div className="promo-intro">
          <img src={titleImage} alt="title" className="promo-intro__header" />
          <div className="promo-intro__subheader">
            <div className="promo-intro__subheader__chance">
              {strings.subtitle_promo_intro_chance}
            </div>
            <div className="promo-intro__subheader__discount">
              {strings.subtitle_promo_intro_discount}
            </div>
            <div className="promo-intro__subheader__end">
              {period}
            </div>
          </div>
          <div className="promo-intro__message">
            <StringRenderer>{value}</StringRenderer>
          </div>
          <ClickableDiv className="promo-intro__button" onClick={viewModel.proceed}>
            {strings.button_promo_intro}
          </ClickableDiv>
          <div className="promo-intro__disclaimer">
            {disclaimer}
          </div>
        </div>
      </IonContent>
      <BasePromoView viewModel={viewModel} />
    </IonPage>
  )
}

export default DefaultPromoIntroPage
