import { inject, injectable } from 'inversify'
import Product from '../../plainTypes/billing/Product'
import Purchase from '../../plainTypes/billing/Purchase'
import WebViewParentPlugin, { WebViewParentPluginEx } from '../webViewParent/WebViewParentPlugin'
import StorePlugin, { BuyResult } from './StorePlugin'

enum StoreEvent {
  GET_PRODUCTS_DATA = 'products',
  RESTORE_PURCHASES = 'restore',
  BUY_PRODUCT = 'buy'
}

@injectable()
export default class WebViewParentStorePlugin implements StorePlugin {
  @inject(WebViewParentPlugin.SYMBOL)
  private readonly webViewParent: WebViewParentPlugin

  buy = (sku: string) => WebViewParentPluginEx.callAndWait<Purchase, BuyResult>(
    this.webViewParent,
    StoreEvent.BUY_PRODUCT,
    { sku },
  )

  restore = () => WebViewParentPluginEx.callAndWait<{ purchases: Purchase[] }, Purchase[]>(
    this.webViewParent,
    StoreEvent.RESTORE_PURCHASES,
    undefined,
    ({ purchases }) => purchases,
  )

  getProductsData = (ofSKUs: string[], highlightedSKU?: string) => {
    const skusJSON = JSON.stringify(ofSKUs)

    return WebViewParentPluginEx.callAndWait<{ products: Product[] }, Product[]>(
      this.webViewParent,
      StoreEvent.GET_PRODUCTS_DATA,
      { skus: skusJSON },
      ({ products }) => products.map((p) => ({
        ...p,
        highlight: p.sku === highlightedSKU,
      })),
    )
  }

  openSubscriptionManagenent = async (): Promise<void> => {}
}
