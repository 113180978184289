import Promo from '../../plainTypes/promo/Promo'
import PromoName from '../../plainTypes/PromoName'

interface PromoRepository {
  checkForPromo(withName?: PromoName): Promise<Promo | undefined>
}

const PromoRepository = {
  SYMBOL: Symbol.for('PromoRepository'),
}

export default PromoRepository
