import { IonAlert } from '@ionic/react'
import React from 'react'
import RestoreController, { RestoreDialogState } from '../../controllers/billing/RestoreController'
import { bindReset } from '../../liveData/bindings'
import useLiveData from '../../liveData/useLiveData'
import useLocalization from '../../plugins/localization/useLocalization'
import AsyncIndicatorsView from '../liveData/AsyncIndicatorsView'
import GoldCongratsAlert from './GoldCongratsAlert'

export type RestoreViewProps = {
  controller: RestoreController
}

const RestoreView: React.FC<RestoreViewProps> = ({
  controller,
}: RestoreViewProps) => {
  const { strings } = useLocalization()
  const [dialogState] = useLiveData(controller.dialogState)
  return (
    <>
      <GoldCongratsAlert
        isOpen={dialogState === RestoreDialogState.RESTORED}
        onDidDismiss={bindReset(controller.dialogState, RestoreDialogState.RESTORED)}
        onContinue={controller.confirmRestoration}
      />
      <IonAlert
        isOpen={dialogState === RestoreDialogState.NOTHING_TO_RESTORE}
        header={strings.dialog_title_settings_norestore}
        message={strings.dialog_message_settings_norestore}
        buttons={[strings.ok]}
        onDidDismiss={bindReset(controller.dialogState, RestoreDialogState.NOTHING_TO_RESTORE)}
      />
      <AsyncIndicatorsView controller={controller} tags={[RestoreController.TAG_RESTORE]} />
    </>
  )
}

export default RestoreView
