import InjectableType from '../../dependencyInjection/InjectableType'

export enum DIScope {
  SINGLETON,
  TRANSIENT
}

interface DIPlugin {
  rebind(symbol: Symbol, scope: DIScope): void
}

const DIPlugin: InjectableType = {
  SYMBOL: Symbol.for('DIPlugin'),
}

export default DIPlugin
