import { injectable } from 'inversify'
import BaseParseService from '../BaseParseService'
import LogService from './LogService'

@injectable()
export default class LogParseService extends BaseParseService implements LogService {
  event = async (name: string, payload: any = undefined) => this.runCloudCode(
    'logEvent', { name, payload },
  )
}
