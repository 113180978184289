import React from 'react'
import { image } from '../../utils/resources/resourceUtils'
import Valentines2022ViewModel from '../../viewModels/valentines/2022'
import DefaultPromoDetailsViewModel from '../../viewModels/promo/DefaultPromoDetailsViewModel'
import DefaultPromoIntroViewModel from '../../viewModels/promo/DefaultPromoIntroViewModel'
import useViewModel from '../../viewModels/useViewModel'
import DefaultPromoDetailPage from '../promo/DefaultPromoDetailPage'
import DefaultPromoIntroPage from '../promo/DefaultPromoIntroPage'
import './2022.scss'

const Intro: React.FC = () => {
  const viewModel: DefaultPromoIntroViewModel = useViewModel(Valentines2022ViewModel.SYMBOL_INTRO)

  return (
    <DefaultPromoIntroPage
      id="valentines-2022-intro"
      className="valentines-2022"
      viewModel={viewModel}
      titleImage={image('valentines/2022/header')}
    />
  )
}

const Detail: React.FC = () => {
  const viewModel: DefaultPromoDetailsViewModel =
    useViewModel(Valentines2022ViewModel.SYMBOL_DETAIL)

  return (
    <DefaultPromoDetailPage
      id="valentines-2022-detail"
      className="valentines-2022"
      viewModel={viewModel}
      imagePath={image('valentines/2022/header')}
    />
  )
}

const Valentines2022 = { Intro, Detail }

export default Valentines2022
