import { inject, injectable } from 'inversify'
import { lazyValueAsync } from '../../utils/function'
import WebViewParentPlugin, { WebViewParentPluginEx } from '../webViewParent/WebViewParentPlugin'
import RuntimePlugin, { Platform } from './RuntimePlugin'

enum RuntimeEvent {
  GET_PLATFORM = 'platform',
}

@injectable()
export default class WebViewParentRuntimePlugin implements RuntimePlugin {
  @inject(WebViewParentPlugin.SYMBOL)
  private readonly webViewParent: WebViewParentPlugin

  platform = lazyValueAsync(
    () => WebViewParentPluginEx.callAndWait<{ platform: Platform }, Platform>(
      this.webViewParent,
      RuntimeEvent.GET_PLATFORM,
      undefined,
      ({ platform }) => platform,
    ),
  )
}
