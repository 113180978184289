import { compare } from '../../utils/OrderComparison'
import when from '../../utils/when'

enum BillingPeriod {
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  ONE_YEAR = 'ONE_YEAR'
}

export default BillingPeriod

export const BillingPeriodEx = {
  referencePeriod: BillingPeriod.ONE_MONTH,
  compare: (bp1: BillingPeriod, bp2: BillingPeriod): number => {
    const values = Object.values(BillingPeriod)
    const index1 = values.indexOf(bp1)
    const index2 = values.indexOf(bp2)

    return compare(index1, index2)
  },
  daysCount: (period: BillingPeriod) => when(period, [
    [BillingPeriod.ONE_WEEK, 7],
    [BillingPeriod.ONE_MONTH, 30],
    [BillingPeriod.THREE_MONTHS, 90],
    [BillingPeriod.SIX_MONTHS, 180],
    [BillingPeriod.ONE_YEAR, 360],
  ]),
}
