import { injectable } from 'inversify'
import Configurations from '../../Configurations'
import AppError from '../../errors/AppError'
import LiveDataImpl from '../../liveData/LiveDataImpl'
import { combine } from '../../utils/path'
import { passTime } from '../../utils/process'
import WebViewParentPlugin, { DownstreamEvent } from './WebViewParentPlugin'

@injectable()
export default class LocationWebViewParentPlugin implements WebViewParentPlugin {
  readonly downstreamEvents = new LiveDataImpl({ event: '' })

  constructor() {
    document.addEventListener(
      'downstreamEvent',
      (({ detail }: CustomEvent) => {
        const event: DownstreamEvent = {
          ...detail,
          error: detail.error
            ? new AppError(detail.error.code, detail.error.message)
            : undefined,
        }
        this.downstreamEvents.set(event)
      }) as ((e: Event) => any),
    )
  }

  dispatchUpstreamEvent = async (event: string, data: { [key: string]: string }) => {
    /* // HACK lbaglie: so, check this one out:
       On some Android devices (e.g., mine), if the below change of location is
       performed too soon after the page has loaded, it just fucking kills the
       whole fucking page rendering, with no warning or error whatsoever.
       Apparently, a simple timeout of 0ms is enough to cover our bases.
     */
    await passTime(0)

    const query = new URLSearchParams(data)
    window.location.href = `${Configurations.APP_SCHEME}://${combine(Configurations.APP_DOMAIN, event)}?${query}`
  }

  finish = (result: { [key: string]: string } = {}) => {
    const query = new URLSearchParams(result)
    window.location.href = `${Configurations.APP_URL}?${query}`
  }
}
