import React from 'react'
import { image } from '../../../utils/resources/resourceUtils'
import DefaultPromoDetailsViewModel from '../../../viewModels/promo/DefaultPromoDetailsViewModel'
import DefaultPromoIntroViewModel from '../../../viewModels/promo/DefaultPromoIntroViewModel'
import useViewModel from '../../../viewModels/useViewModel'
import DefaultPromoDetailPage from '../../promo/DefaultPromoDetailPage'
import DefaultPromoIntroPage from '../../promo/DefaultPromoIntroPage'
import './2024.scss'
import CyberMonday2024ViewModel from '../../../viewModels/cybermonday/2024'

const Intro: React.FC = () => {
  const viewModel: DefaultPromoIntroViewModel = useViewModel(CyberMonday2024ViewModel.SYMBOL_INTRO)

  return (
    <DefaultPromoIntroPage
      id="cyberMonday-2024-intro"
      className="promoCyberMonday-2024"
      viewModel={viewModel}
      titleImage={image('cyberMonday/2024/header')}
    />
  )
}

const Detail: React.FC = () => {
  const viewModel: DefaultPromoDetailsViewModel =
    useViewModel(CyberMonday2024ViewModel.SYMBOL_DETAIL)

  return (
    <DefaultPromoDetailPage
      id="cyberMonday-2024-detail"
      className="promoCyberMonday-2024"
      viewModel={viewModel}
      imagePath={image('cyberMonday/2024/header')}
    />
  )
}

const CyberMonday2024 = { Intro, Detail }

export default CyberMonday2024