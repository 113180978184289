import React from 'react'
import { image } from '../../utils/resources/resourceUtils'
import Carnival2023ViewModel from '../../viewModels/carnival/2023'
import DefaultPromoDetailsViewModel from '../../viewModels/promo/DefaultPromoDetailsViewModel'
import DefaultPromoIntroViewModel from '../../viewModels/promo/DefaultPromoIntroViewModel'
import useViewModel from '../../viewModels/useViewModel'
import DefaultPromoDetailPage from '../promo/DefaultPromoDetailPage'
import DefaultPromoIntroPage from '../promo/DefaultPromoIntroPage'
import './2023.scss'

const Intro: React.FC = () => {
  const viewModel: DefaultPromoIntroViewModel = useViewModel(Carnival2023ViewModel.SYMBOL_INTRO)

  return (
    <DefaultPromoIntroPage
      id="carnival-2023-intro"
      className="carnival-2023"
      viewModel={viewModel}
      titleImage={image('carnival/2023/header')}
    />
  )
}

const Detail: React.FC = () => {
  const viewModel: DefaultPromoDetailsViewModel =
    useViewModel(Carnival2023ViewModel.SYMBOL_DETAIL)

  return (
    <DefaultPromoDetailPage
      id="carnival-2023-detail"
      className="carnival-2023"
      viewModel={viewModel}
      imagePath={image('carnival/2023/header')}
    />
  )
}

const Carnival2023 = { Intro, Detail }

export default Carnival2023
