import { inject, injectable } from 'inversify'
import PromoName from '../../plainTypes/PromoName'
import PromoService from '../../services/promo/PromoService'
import PromoRepository from './PromoRepository'

@injectable()
export default class PromoRepositoryImpl implements PromoRepository {
  @inject(PromoService.SYMBOL)
  private readonly service: PromoService

  checkForPromo = async (promoName?: PromoName) => this.service.checkForPromo(promoName)
}
