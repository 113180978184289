import './Clickable.scss'

import React from 'react'
import { IonRippleEffect } from '@ionic/react'
import { cssModify } from '../css'

export interface ClickableDivProps {
  className?: string
  ripples?: boolean
  onClick?: (args: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const ClickableDiv: React.FC<React.PropsWithChildren<ClickableDivProps>> = ({
  children, className, ripples, onClick,
}: React.PropsWithChildren<ClickableDivProps>) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
  <div
    className={`${
      cssModify('clickable', ripples !== false, 'ripples')
    } ${
      onClick && ripples !== false ? 'ion-activatable' : ''
    } ${className}`}
    role="button"
    onClick={onClick}
  >
    {children}
    <IonRippleEffect />
  </div>
)

export default ClickableDiv
