import { isNumber } from 'lodash'

enum NotificationType {
  // lbaglie: leaving it here for when it is needed
  SOMETHING
}

export default NotificationType

const allValues = Object.values(NotificationType).filter((v) => isNumber(v))
  .map((v) => v as NotificationType)

export const NotificationTypeEx = {
  allValues,
}
