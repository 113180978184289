import React from 'react'
import { image } from '../../utils/resources/resourceUtils'
import ValentinesQuickie2022ViewModel from '../../viewModels/valentinesQuickie/2022'
import DefaultPromoDetailsViewModel from '../../viewModels/promo/DefaultPromoDetailsViewModel'
import DefaultPromoIntroViewModel from '../../viewModels/promo/DefaultPromoIntroViewModel'
import useViewModel from '../../viewModels/useViewModel'
import DefaultPromoDetailPage from '../promo/DefaultPromoDetailPage'
import DefaultPromoIntroPage from '../promo/DefaultPromoIntroPage'
import './2022.scss'

const Intro: React.FC = () => {
  const viewModel: DefaultPromoIntroViewModel = useViewModel(ValentinesQuickie2022ViewModel.SYMBOL_INTRO)

  return (
    <DefaultPromoIntroPage
      id="valentinesquickie-2022-intro"
      className="valentinesquickie-2022"
      viewModel={viewModel}
      titleImage={image('valentinesQuickie/2022/header')}
    />
  )
}

const Detail: React.FC = () => {
  const viewModel: DefaultPromoDetailsViewModel =
    useViewModel(ValentinesQuickie2022ViewModel.SYMBOL_DETAIL)

  return (
    <DefaultPromoDetailPage
      id="valentinesquickie-2022-intro"
      className="valentinesquickie-2022"
      viewModel={viewModel}
      imagePath={image('valentinesQuickie/2022/header')}
    />
  )
}

const ValentinesQuickie2022 = { Intro, Detail }

export default ValentinesQuickie2022
