import { contains } from '../utils/array'
import when from '../utils/when'

// lbaglie: same order as in the Android app
enum Gender {
  WOMAN = 'WOMAN',
  TRANSEXUAL_WOMAN = 'TRANSEXUAL_WOMAN',
  MAN = 'MAN',
  TRANSEXUAL_MAN = 'TRANSEXUAL_MAN',
  COUPLE_MAN_WOMAN = 'COUPLE_MAN_WOMAN',
  COUPLE_MAN_MAN = 'COUPLE_MAN_MAN',
  COUPLE_WOMAN_WOMAN = 'COUPLE_WOMAN_WOMAN',
  CROSSDRESSER = 'CROSSDRESSER',
  TRANSVESTITE = 'TRANSVESTITE',
}

export default Gender

const allValues = Object.values(Gender)

export const GenderEx = {
  default: allValues[0],
  isCouple: (gender: Gender) => contains(gender, 'COUPLE'),
  isMan: (gender: Gender) => contains(gender, 'MAN'),
  isWoman: (gender: Gender) => contains(gender, 'WOMAN'),
  hasAll: (genders: Set<Gender>) => genders.size === allValues.length,
  allValues,
  membersGenders: (gender: Gender) => when(gender, [
    [Gender.COUPLE_MAN_MAN, () => [Gender.MAN, Gender.MAN]],
    [Gender.COUPLE_MAN_WOMAN, () => [Gender.MAN, Gender.WOMAN]],
    [Gender.COUPLE_WOMAN_WOMAN, () => [Gender.WOMAN, Gender.WOMAN]],
    [gender, () => [gender]],
  ]),
}
