import { format } from 'date-fns'

export function formatDate(date: Date, includeTime: boolean = true): string {
  return format(date, `DD/MM/YYYY${includeTime ? ' HH:mm' : ''}`)
}

export function formatTime(date: Date): string {
  return format(date, 'HH:mm')
}

export function formatFormalDate(date: Date): string {
  return format(date, 'DD MMMM YYYY')
}

export function formatDayMonth(date: Date): string {
  return format(date, 'DD/MM')
}
