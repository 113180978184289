import { identity } from 'lodash'
import LiveDataImpl from './LiveDataImpl'
import UndefinableLiveData from './UndefinableLiveData'

export default class UndefinableLiveDataImpl<T> extends LiveDataImpl<T | undefined>
  implements UndefinableLiveData<T> {
  constructor(
    initial?: T,
    prepareNewValue: (newValue: T | undefined) => T | undefined = identity,
  ) {
    super(initial, prepareNewValue)
  }

  get value(): T | undefined {
    return this.mValue
  }

  get hasValue(): boolean {
    return this.mValue !== undefined
  }
}
