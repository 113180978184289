import React from 'react'
import { LastLocationProvider } from 'react-router-last-location'
import { IoCContextProvider } from '../dependencyInjection/IoCContext'
import { SingletonsContextProvider } from './singletonComp/SingletonComponent'

export default function ContextsProvider({ children }: React.PropsWithChildren<{}>) {
  return (
    <>
      <LastLocationProvider>
        <IoCContextProvider>
          <SingletonsContextProvider>
            {children}
          </SingletonsContextProvider>
        </IoCContextProvider>
      </LastLocationProvider>
    </>
  )
}
