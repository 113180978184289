import { IonAlert } from '@ionic/react'
import React from 'react'
import PurchaseModal from '../../components/purchase/PurchaseModal'
import { bindReset } from '../../liveData/bindings'
import useLiveData from '../../liveData/useLiveData'
import useLocalization from '../../plugins/localization/useLocalization'
import DefaultPromoDetailsViewModel, { DefaultPromoDetailDialogState } from '../../viewModels/promo/DefaultPromoDetailsViewModel'
import BasePromoView from './BasePromoView'

type BasePromoDetailViewProps = {
  viewModel: DefaultPromoDetailsViewModel
  purchaseMessage?: string
}

const BasePromoDetailView: React.FC<BasePromoDetailViewProps> = ({
  viewModel, purchaseMessage,
}: BasePromoDetailViewProps) => {
  const { strings } = useLocalization()
  const [dialogState] = useLiveData(viewModel.dialogState)

  return (
    <>
      <IonAlert
        isOpen={dialogState === DefaultPromoDetailDialogState.NO_STORE}
        backdropDismiss={false}
        header={strings.dialog_title_nostore}
        message={strings.dialog_message_nostore}
        buttons={[{ text: strings.ok, handler: () => viewModel.close() }]}
      />
      <PurchaseModal
        isOpen={dialogState === DefaultPromoDetailDialogState.PURCHASE}
        onDidDismiss={bindReset(viewModel.dialogState, DefaultPromoDetailDialogState.PURCHASE)}
        purchaseController={viewModel.purchaseController}
        restoreController={viewModel.restoreController}
        message={purchaseMessage}
      />
      <BasePromoView viewModel={viewModel} />
    </>
  )
}

export default BasePromoDetailView
