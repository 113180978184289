import { inject, injectable } from 'inversify'
import LogService from '../../services/log/LogService'
import LogRepository from './LogRepository'

@injectable()
export default class LogRepositoryImpl implements LogRepository {
  @inject(LogService.SYMBOL)
  private readonly service: LogService

  // TODO lbaglie: check hot to properly log stuff here.
  navigation = (route: string) => Promise.resolve(undefined)
  // this.service.event('USER_NAVIGATED', { route })
}
