enum PromoName {
  BLACK_FRIDAY_2020 = 'blackweek2020',
  VALENTINES_2021 = 'valentines2021',
  WELCOME = 'welcome',
  BLACK_FRIDAY_2021 = 'blackweek2021',
  CYBER_MONDAY_2021 = 'cybermonday2021',
  VALENTINES_2022 = 'valentines2022',
  VALENTINES_QUICKIE_2022 = 'valentinesquickie2022',
  BLACK_FRIDAY_2022 = 'blackweek2022',
  CYBER_MONDAY_2022 = 'cybermonday2022',
  CARNIVAL_2023 = 'carnival2023',
  VALENTINES_2023 = 'valentines2023',
  PROMO69_2023 = 'meianove2023',
  BLACK_FRIDAY_2023 = 'blackfriday2023',
  CYBER_MONDAY_2023 = 'cybermonday2023',
  CARNIVAL_2024 = 'carnival2024',
  VALENTINES_2024 = 'valentines2024',
  SIXTYNINE_2024 = 'sixtynine2024',
  BLACKFRIDAY_2024 = 'blackfriday2024',
  CYBERMONDAY_2024 = 'cybermonday2024'
}

export default PromoName

export const PromoNameEx = {
  allValues: Object.values(PromoName),
}
