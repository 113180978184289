import { inject, injectable } from 'inversify'
import LiveDataImpl from '../../liveData/LiveDataImpl'
import Navigator from '../../navigation/Navigator'
import Promo from '../../plainTypes/promo/Promo'
import PromoName from '../../plainTypes/PromoName'
import RuntimePlugin from '../../plugins/runtime/RuntimePlugin'
import PromoRepository from '../../repositories/promo/PromoRepository'
import AsyncViewModelImpl from '../AsyncViewModelImpl'
import BasePromoViewModel from './BasePromoViewModel'

@injectable()
export default abstract class BasePromoViewModelImpl
  extends AsyncViewModelImpl
  implements BasePromoViewModel {
  @inject(PromoRepository.SYMBOL)
  protected readonly promoRepository: PromoRepository

  @inject(Navigator.SYMBOL)
  protected readonly navigator: Navigator

  @inject(RuntimePlugin.SYMBOL)
  protected readonly runtime: RuntimePlugin

  readonly isAvailable = new LiveDataImpl(true)

  protected abstract readonly promoName: PromoName

  readonly init = () => this.performAsync(
    async () => {
      const promo: Promo | undefined = await this.promoRepository.checkForPromo(this.promoName)
      this.isAvailable.set(!!promo)
      if (promo) {
        this.initPromo(promo)
      }
    },
    BasePromoViewModel.TAG_INIT,
  )
    .catch(() => this.isAvailable.set(false))

  // eslint-disable-next-line class-methods-use-this
  protected initPromo(promo: Promo) {}

  abstract proceed(): void

  close = () => this.navigator.close()
}
