import { IonText } from '@ionic/react'
import React from 'react'
import useIoC from '../../dependencyInjection/useIoC'
import SimpleBrowserPlugin from '../../plugins/browser/SimpleBrowserPlugin'
import when from '../../utils/when'
import ClickableAnchor from '../clickable/ClickableAnchor'

export type EULADisclaimerProps = {
  textComponents: string[]
  tosIndex: number
  privacyIndex: number
  color?: string
  className?: string
}

const EULADisclaimer: React.FC<EULADisclaimerProps> = ({
  className, color, textComponents, tosIndex, privacyIndex,
}: EULADisclaimerProps) => {
  const { openToS, openPrivacyStatement }: SimpleBrowserPlugin =
    useIoC().get(SimpleBrowserPlugin.SYMBOL)

  return (
    <div className={className}>
      <IonText color={color}>
        {textComponents.map((comp, idx) => when(idx, [
          [tosIndex, () => <ClickableAnchor key={comp} onClick={openToS}>{comp}</ClickableAnchor>],
          [privacyIndex, () => (
            <ClickableAnchor key={comp} onClick={openPrivacyStatement}>
              {comp}
            </ClickableAnchor>
          )],
          [idx, () => <span key={comp}>{comp}</span>],
        ]))}
.
      </IonText>
    </div>
  )
}

export default EULADisclaimer
