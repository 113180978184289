import { OverlayEventDetail } from '@ionic/core'
import { AlertButton, IonAlert } from '@ionic/react'
import React from 'react'
import './SpecialAlert.scss'

export type SpecialAlertProps = {
  isOpen: boolean
  header?: string
  message?: string
  button: (AlertButton | string);
  onDidDismiss: (event: CustomEvent<OverlayEventDetail>) => void
  backdropDismiss?: boolean
}

const SpecialAlert: React.FC<SpecialAlertProps> = ({
  isOpen, onDidDismiss, header, message, button, backdropDismiss,
}: SpecialAlertProps) => (
  <IonAlert
    key={`special-alert_${message}`}
    backdropDismiss={backdropDismiss}
    cssClass="special-alert"
    isOpen={isOpen}
    onDidDismiss={onDidDismiss}
    header={header}
    message={message}
    buttons={[button]}
  />
)

export default SpecialAlert
