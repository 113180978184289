/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable semi */
import { inject, injectable } from "inversify";
import { maxBy } from "lodash";
import AppErrorCode from "../../errors/AppErrorCode";
import LiveDataImpl from "../../liveData/LiveDataImpl";
import TriggerImpl from "../../liveData/TriggerImpl";
import { BillingPeriodEx } from "../../plainTypes/billing/BillingPeriod";
import Product, { ProductEx } from "../../plainTypes/billing/Product";
import SubscriberType from "../../plainTypes/user/SubscriberType";
import BillingRepository from "../../repositories/billing/BillingRepository";
import { formatCurrency } from "../../utils/formatting/currency";
import AsyncControllerImpl from "../AsyncControllerImpl";
import SelectionControllerImpl from "../selection/SelectionControllerImpl";
import PurchaseController, {
  PurchaseDialogState,
  PurchaseItem,
} from "./PurchaseController";

@injectable()
export default class PurchaseControllerImpl
  extends AsyncControllerImpl
  implements PurchaseController
{
  @inject(BillingRepository.SYMBOL)
  private readonly billingRepository: BillingRepository;

  readonly selectionController = new SelectionControllerImpl<PurchaseItem>();

  readonly dialogState = new LiveDataImpl(PurchaseDialogState.NONE);

  readonly couldNotLoad = new TriggerImpl();

  readonly purchased = new TriggerImpl();

  init = async (skus?: string[]) =>
    this.performAsyncSafe(
      async () => {
        const realProducts = skus
          ? await this.billingRepository
              .getProductsData(skus)
              .then((products) => {
                if (
                  !products.length ||
                  products.find(({ highlight }) => highlight)
                ) {
                  return products;
                }

                const highlighted = maxBy(products, "price")!;
                return products.map((p) => ({
                  ...p,
                  highlight: p.sku === highlighted.sku,
                }));
              })
          : await this.billingRepository.getSubscriptions(SubscriberType.GOLD);

        const reference = realProducts.find(
          (p) => p.billingPeriod === BillingPeriodEx.referencePeriod
        );
        const { strings } = this.localization;
        const items = reference
          ? realProducts.map((p) => {
              const priceInReference = ProductEx.priceInPeriod(
                p,
                BillingPeriodEx.referencePeriod
              );
              const discount = (1 - priceInReference / reference.price) * 100;
              return {
                ...this.mapToItem(p),
                discount: discount
                  ? strings.template_message_purchase_discount(
                      Math.round(discount)
                    )
                  : "",
              };
            })
          : realProducts.map(this.mapToItem);

        this.selectionController.init(items);
      },
      PurchaseController.TAG_INIT,
      (e) => {
        if (e.is(AppErrorCode.APP_COULDNT_CONNECT_STORE)) {
          this.couldNotLoad.fire();
          return true;
        }
        return false;
      }
    );

  private mapToItem = (p: Product): PurchaseItem => ({
    discount: "",
    fullPrice: formatCurrency(p.price, p.currency),
    highlight: p.highlight,
    referencePrice:
      this.localization.strings.template_message_purchase_reference(
        ProductEx.priceInPeriod(p, BillingPeriodEx.referencePeriod),
        p.currency
      ),
    product: p,
  });

  selectSubscription = (item: PurchaseItem) => {
    if (item === this.selectionController.selectedItem.value) {
      this.subscribeToSelected();
    } else {
      this.selectionController.selectItem(item);
    }
  };

  subscribeToSelected = () =>
    this.performAsyncSafe(async () => {
      const { isRestore } = await this.billingRepository.buy(
        this.selectionController.selectedItem.value!.product.sku
      );
      this.dialogState.set(
        isRestore
          ? PurchaseDialogState.SUBSCRIBED_RESTORED
          : PurchaseDialogState.SUBSCRIBED
      );
    }, PurchaseController.TAG_PURCHASE);

  finish = this.purchased.fire;
}
