export function cutOff(
  str: string | undefined, length: number, ellipsis: string = '...',
): string | undefined {
  if (!str) {
    return str
  }

  return str.length > length
    ? str.substr(0, length) + ellipsis
    : str
}

export function isEmail(email: string | null | undefined) {
  if (!email) {
    return false
  }

  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const REGEX_DATE = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-1][0-9](:[0-6][0-9]){2}\.[0-9]{3}Z$/

export function isDateString(dateString: string | undefined | null): boolean {
  return !!dateString?.match(REGEX_DATE)
}

export const CURRENT_LOCALE = window?.navigator?.languages[0] ??
  window?.navigator?.language ??
  'en-us'
