enum SubscriberType {
  FREE = 'FREE',
  GOLD = 'GOLD'
}

export default SubscriberType

const allValues = Object.values(SubscriberType)

export const SubscriberTypeEx = {
  isPayer: (type: SubscriberType) => type !== SubscriberType.FREE,
  referenceName: (type: SubscriberType) => SubscriberType[type].toLowerCase(),
  default: allValues[0] as SubscriberType,
}
