import { isNil } from 'lodash'
import { LiveDataEx } from '../../liveData/LiveData'
import LiveDataImpl from '../../liveData/LiveDataImpl'
import UndefinableLiveDataImpl from '../../liveData/UndefinableLiveDataImpl'
import SelectionController, { SelectionItem } from './SelectionController'

export default class SelectionControllerImpl<T> implements SelectionController<T> {
  readonly items = new LiveDataImpl<SelectionItem<T>[]>([])

  readonly selectedItem = new UndefinableLiveDataImpl<T>(
    undefined,
    (value) => {
      const items = this.items.value
      const newItems = [...items]

      if (this.selectedItem.hasValue) {
        const oldSelectedIdx = items.findIndex(({ selected }) => selected)
        if (oldSelectedIdx >= 0) {
          newItems[oldSelectedIdx].selected = false
        }
      }

      const index = this.items.value.map((i) => i.item).indexOf(value!)

      if (index < 0) {
        return undefined
      }

      newItems[index].selected = true
      this.items.set(newItems)
      return value
    },
  )

  protected initFun(items: T[], selectedItem?: T): void {
    this.items.set(items.map((item): SelectionItem<T> => ({ item })))

    if (!isNil(selectedItem)) {
      this.selectItem(selectedItem)
    }
  }

  init = (items: T[], selectedItem?: T) => this.initFun(items, selectedItem)

  protected deinitFun(): void {
    LiveDataEx.disposeAllIn(this)
  }

  deinit = () => this.deinitFun()

  select = (idx: number) => this.selectedItem.set(this.items.value[idx].item)

  selectItem = this.selectedItem.set
}
