import LiveData from '../../liveData/LiveData'
import Trigger from '../../liveData/Trigger'
import Product from '../../plainTypes/billing/Product'
import AsyncController from '../AsyncController'
import SelectionController from '../selection/SelectionController'

export type PurchaseItem = {
  product: Product
  highlight?: boolean
  fullPrice: string
  referencePrice: string
  discount: string
}

export enum PurchaseDialogState {
  NONE,
  SUBSCRIBED,
  SUBSCRIBED_RESTORED,
}

interface PurchaseController extends AsyncController {
  readonly selectionController: SelectionController<PurchaseItem>
  readonly dialogState: LiveData<PurchaseDialogState>
  readonly couldNotLoad: Trigger
  readonly purchased: Trigger

  init(products?: string[]): void

  selectSubscription(item: PurchaseItem): void
  subscribeToSelected(): void
  finish(): void
}

const PurchaseController = {
  SYMBOL: Symbol.for('PurchaseController'),
  TAG_INIT: 'TAG_INIT',
  TAG_PURCHASE: 'TAG_PURCHASE',
}

export default PurchaseController
