import React from 'react'
import LiveData from '../../liveData/LiveData'
import ActionData from '../../plainTypes/ActionData'
import LiveDataErrorToast from './LiveDataErrorToast'
import LiveDataLoading from './LiveDataLoading'

export interface AsyncIndicatorsViewProps {
  controller: {
    loading: LiveData<ActionData<boolean>>,
    error: LiveData<ActionData<Error> | undefined>
  }
  tags?: string[]
  loadingTags?: string[]
  errorTags?: string[]
  autoHookToPager?: boolean
}

const AsyncIndicatorsView: React.FC<AsyncIndicatorsViewProps> = ({
  controller, tags, loadingTags, errorTags, autoHookToPager,
}: AsyncIndicatorsViewProps) => {
  const fixdTags = (tags ?? [])
  const someErrorTags = (errorTags ?? [])
    .concat(fixdTags)
  const someLoadingTags = (loadingTags ?? [])
    .concat(fixdTags)
  return (
    <>
      <LiveDataLoading liveData={controller.loading} tags={someLoadingTags} />
      <LiveDataErrorToast liveData={controller.error} tags={someErrorTags} />
    </>
  )
}

export default AsyncIndicatorsView
