import { identity } from 'lodash'
import ObservableData from '../../liveData/ObservableData'

export type DownstreamEvent = {
  event: string
  data?: {}
  error?: Error
}

interface WebViewParentPlugin {
  readonly downstreamEvents: ObservableData<DownstreamEvent>
  dispatchUpstreamEvent(
    event: string, data?: { [key: string]: string }
  ): Promise<void>
  finish(result?: { [key: string]: string }): void
}

const WebViewParentPlugin = {
  SYMBOL: Symbol.for('WebViewParentPlugin'),
}

export default WebViewParentPlugin

export const WebViewParentPluginEx = {
  callAndWait: <TEvent, TPromise = TEvent>(
    webViewParent: WebViewParentPlugin,
    eventName: string,
    upstreamData?: { [key: string]: string },
    map: (data: TEvent) => TPromise = identity,
  ): Promise<TPromise> => new Promise<TPromise>((resolve, reject) => {
    const disposable = webViewParent.downstreamEvents.observe(
      ({ event, data, error }) => {
        if (event !== eventName) {
          return
        }

        disposable.dispose()
        if (error) {
          reject(error)
        } else {
          resolve(map(data as TEvent))
        }
      },
    )
    webViewParent.dispatchUpstreamEvent(eventName, upstreamData)
  }),
}
