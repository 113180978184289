// eslint-disable-next-line import/no-extraneous-dependencies
import { History, Location, LocationDescriptor } from 'history'
import { injectable } from 'inversify'
import PromoName from '../plainTypes/PromoName'
import WebViewParentPlugin from '../plugins/webViewParent/WebViewParentPlugin'
import LogRepository from '../repositories/log/LogRepository'
import { DO_NOTHING } from '../utils/function'
import { combine } from '../utils/path'
import Navigator, { NavigatorOptions } from './Navigator'
import * as AppRoutes from './Routes'

type LocationState = {
  previousLocation?: Location
}

@injectable()
export default class HistoryNavigator implements Navigator {
  constructor(
    private readonly parent: WebViewParentPlugin,
    private readonly logRepository: LogRepository,
    private readonly history: History<any>,
    private readonly webLastLocation?: Location<any>,
  ) {}

  private push = (
    route: string, query?: any,
    { replaces, fromPath, useLocation }: NavigatorOptions = { },
  ) => {
    this.logRepository.navigation(route).catch(DO_NOTHING)

    const queryString = Object.entries(query ?? {})
      .map(([key, value]) => `${key}=${value ?? ''}`)
      .join('&')

    const locationDescriptor: LocationDescriptor = {
      pathname: fromPath ? combine(fromPath, route) : route,
      search: `?${queryString}`,
      state: {
        previousLocation: this.history.location,
      },
    }

    if (useLocation) {
      window.location.href = locationDescriptor.pathname! + locationDescriptor.search
      return
    }

    if (replaces) {
      this.history.replace(locationDescriptor)
      return
    }

    this.history.push(locationDescriptor)
  }

  promoDetail = (name: PromoName, options?: NavigatorOptions) => this.push(
    AppRoutes.PROMO_DETAIL(name), null, options,
  )

  back = () => {
    if (this.webLastLocation) {
      this.logRepository.navigation(this.webLastLocation.pathname)
    }

    const currentState: LocationState | undefined = this.history.location.state

    if (currentState?.previousLocation) {
      this.history.goBack()
    } else {
      this.close()
    }
  }

  close = (afterPurchasing: boolean = false) => {
    this.parent.finish({ didPurchase: afterPurchasing.toString() })
  }

  getQuery = <T>(): T => {
    const params = new URLSearchParams(this.history.location.search)
    const query = Array.from(params.entries()).reduce((agg, [key, value]) => {
      agg[key] = value // eslint-disable-line no-param-reassign
      return agg
    }, {} as { [key: string]: any })
    return query as T
  }

  isIn = (route: string) => new RegExp(`${route}(/|$)`).test(window.location.pathname)
}
