import BaseProduct from './BaseProduct'
import BillingPeriod, { BillingPeriodEx } from './BillingPeriod'
import ValuedThing from './ValuedThing'

type Product = BaseProduct & ValuedThing & Readonly<{
  title?: string
  description?: string
  billingPeriod?: BillingPeriod
}>

export default Product

export const ProductEx = {
  priceInPeriod: (of: Product, period: BillingPeriod): number => {
    const divisor = BillingPeriodEx.daysCount(of.billingPeriod!) /
      BillingPeriodEx.daysCount(period)
    return of.price / divisor
  },
}
