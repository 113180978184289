import { inject, injectable } from 'inversify'
import { combine } from '../../utils/path'
import WebViewParentPlugin from '../webViewParent/WebViewParentPlugin'
import SimpleBrowserPlugin from './SimpleBrowserPlugin'

const URL_APP = 'https://ysosapp.com.br'
const URL_TERMS_OF_SERVICE = combine(URL_APP, 'termos')
const URL_PRIVACY_POLICY = combine(URL_APP, 'politica-de-privacidade')

enum BrowserEvent {
  OPEN_URL = 'externalBrowser'
}

@injectable()
export default class WebViewParentSimpleBrowserPlugin implements SimpleBrowserPlugin {
  @inject(WebViewParentPlugin.SYMBOL)
  private readonly webviewParent: WebViewParentPlugin

  open = (url: string) => this.webviewParent.dispatchUpstreamEvent(
    BrowserEvent.OPEN_URL, { url },
  )

  openToS = () => this.open(URL_TERMS_OF_SERVICE)

  openPrivacyStatement = () => this.open(URL_PRIVACY_POLICY)
}
