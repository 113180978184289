export type Constructable = { new(...args: any[]): any }

type DIMapping = Map<symbol, Constructable>

export default DIMapping

function newMapping(mappings: [symbol, Constructable][]): DIMapping {
  return new Map(mappings)
}

export const DIMappingEx = {
  new: newMapping,
  merge: (...mappings: DIMapping[]): DIMapping => newMapping(
    mappings.flatMap((m) => Array.from(m.entries())),
  ),
}
