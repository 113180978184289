import { Container, injectable } from 'inversify'
import DIMapping from '../../dependencyInjection/DIMapping'
import DIPlugin, { DIScope } from './DIPlugin'

@injectable()
export default class DIPluginImpl implements DIPlugin {
  constructor(
    private readonly container: Container,
    private readonly mappings: DIMapping,
  ) {}

  rebind = (symbol: symbol, scope: DIScope) => {
    this.container.unbind(symbol)
    const binding = this.container.bind(symbol).to(this.mappings.get(symbol)!)

    switch (scope) {
      case DIScope.SINGLETON:
        binding.inSingletonScope()
        break
      case DIScope.TRANSIENT:
        binding.inTransientScope()
        break
      default: throw new Error(`unknown DIScope ${scope}`)
    }
  }
}
