import Promo from '../../plainTypes/promo/Promo'
import PromoName from '../../plainTypes/PromoName'

interface PromoService {
  checkForPromo(promoName?: PromoName): Promise<Promo | undefined>
}

const PromoService = {
  SYMBOL: Symbol.for('PromoService'),
}

export default PromoService
