/* eslint-disable import/prefer-default-export */
import {
  differenceInDays, differenceInMonths, differenceInYears, differenceInHours,
  differenceInMinutes, differenceInSeconds,
} from 'date-fns'
import { entriesOf } from './object'

export enum DateUnit {
  DAY,
  MONTH,
  YEAR
}

type DateComponents = { [key in DateUnit]: number }

export enum TimeUnit {
  HOUR,
  MINUTE,
  SECOND
}

type TimeComponents = { [key in DateUnit]: number }

export function dateBetween(startDate: Date, andEndDate: Date): DateComponents {
  return {
    [DateUnit.YEAR]: differenceInYears(andEndDate, startDate),
    [DateUnit.MONTH]: differenceInMonths(andEndDate, startDate),
    [DateUnit.DAY]: differenceInDays(andEndDate, startDate),
  }
}

export function timeBetween(startDate: Date, andEndDate: Date): TimeComponents {
  const hours = differenceInHours(andEndDate, startDate)
  const minutes = differenceInMinutes(andEndDate, startDate) - hours * 60
  const seconds = differenceInSeconds(andEndDate, startDate) - minutes * 60 - hours * 60 * 60
  return {
    [TimeUnit.HOUR]: hours,
    [TimeUnit.MINUTE]: minutes,
    [TimeUnit.SECOND]: seconds,
  }
}

export function maximumDateComponent(inComp: DateComponents): [DateUnit, number] {
  const found = entriesOf(inComp).find(([, value]) => value > 0)

  // lbaglie: Object.entries unfortunately converts keys to string :facepalm:
  return found ? [parseInt(found[0].toString(), 10), found[1]] : [DateUnit.DAY, 0]
}
