import InjectableType from '../../dependencyInjection/InjectableType'
import Product from '../../plainTypes/billing/Product'
import Purchase from '../../plainTypes/billing/Purchase'

export type BuyResult = Readonly<{
  purchase: Purchase[]
  isRestore?: boolean
}>

interface StorePlugin {
  buy(sku: string): Promise<BuyResult>

  restore(): Promise<Purchase[]>

  getProductsData(ofSKUs: string[], highlightedSKU?: string): Promise<Product[]>

  openSubscriptionManagenent(): Promise<void>
}

const StorePlugin: InjectableType = {
  SYMBOL: Symbol.for('StorePlugin'),
}

export default StorePlugin
