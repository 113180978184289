import {
  IonButton, IonContent, IonIcon, IonPage,
} from '@ionic/react'
import { checkmark, chevronBack } from 'ionicons/icons'
import React, { useEffect } from 'react'
import ClickableDiv from '../../components/clickable/ClickableDiv'
import EULADisclaimer from '../../components/eulaDisclaimer/EULADisclaimer'
import StringRenderer from '../../components/StringRenderer'
import useLiveData from '../../liveData/useLiveData'
import useLocalization from '../../plugins/localization/useLocalization'
import DefaultPromoDetailsViewModel from '../../viewModels/promo/DefaultPromoDetailsViewModel'
import BasePromoDetailView from './BasePromoDetailView'
import './DefaultPromoDetailPage.scss'

export type DefaultPromoDetailProps = Readonly<{
  id: string
  className?: string
  viewModel: DefaultPromoDetailsViewModel
  imagePath: string
}>

const DefaultPromoDetailPage: React.FC<DefaultPromoDetailProps> = ({
  id, className, viewModel, imagePath,
}: DefaultPromoDetailProps) => {
  const { strings } = useLocalization()

  const [messages] = useLiveData(viewModel.messages)

  useEffect(() => {
    viewModel.init()
    return viewModel.deinit
  }, [viewModel])

  return (
    <IonPage id={id} className={className}>
      <IonContent className="promo-detail-container">
        <IonButton onClick={viewModel.back} className="promo-detail__close">
          <IonIcon icon={chevronBack} />
        </IonButton>
        <div className="promo-detail">
          <img src={imagePath} alt="title" className="promo-detail__header" />
          <div className="promo-detail__subheader">{messages.sub}</div>
          <div className="promo-detail__message">
            <StringRenderer>{messages.main}</StringRenderer>
          </div>
          <div className="promo-detail__item-container">
            {strings.items_promo_detail.map((item) => (
              <div key={item} className="promo-detail__item">
                <IonIcon icon={checkmark} className="promo-detail__item__check" />
                <div className="promo-detail__item__text">{item}</div>
              </div>
            ))}
          </div>
          {viewModel.error.hasValue
            ? null
            : (
              <ClickableDiv className="promo-detail__button" onClick={viewModel.proceed}>
                <span className="promo-detail__button__top">{messages.button}</span>
                {strings.button_disclaimer_promo_detail}
              </ClickableDiv>
            )}
          <EULADisclaimer
            className="promo-detail__terms"
            textComponents={strings.terms_eula.components}
            privacyIndex={strings.terms_eula.privacyIndex}
            tosIndex={strings.terms_eula.tosIndex}
          />
        </div>
      </IonContent>
      <BasePromoDetailView viewModel={viewModel} purchaseMessage={messages.purchase} />
    </IonPage>
  )
}

export default DefaultPromoDetailPage
