import Product from '../../plainTypes/billing/Product'
import SubscriberType from '../../plainTypes/user/SubscriberType'

interface BillingRepository {
  getSubscriptions(ofType: SubscriberType): Promise<Product[]>
  getProductsData(skus: string[]): Promise<Product[]>
  buy(sky: string): Promise<{ isRestore: boolean }>
  restorePurchases(): Promise<{ didRestore: boolean }>
}

const BillingRepository = {
  SYMBOL: Symbol.for('BillingRepository'),
}

export default BillingRepository
