import React from 'react'
import { image } from '../../utils/resources/resourceUtils'
import Valentines2024ViewModel from '../../viewModels/valentines/2024'
import DefaultPromoDetailsViewModel from '../../viewModels/promo/DefaultPromoDetailsViewModel'
import DefaultPromoIntroViewModel from '../../viewModels/promo/DefaultPromoIntroViewModel'
import useViewModel from '../../viewModels/useViewModel'
import DefaultPromoDetailPage from '../promo/DefaultPromoDetailPage'
import DefaultPromoIntroPage from '../promo/DefaultPromoIntroPage'
import './2024.scss'

const Intro: React.FC = () => {
  const viewModel: DefaultPromoIntroViewModel = useViewModel(Valentines2024ViewModel.SYMBOL_INTRO)

  return (
    <DefaultPromoIntroPage
      id="valentines-2024-intro"
      className="valentines-2024"
      viewModel={viewModel}
      titleImage={image('valentines/2024/header')}
    />
  )
}

const Detail: React.FC = () => {
  const viewModel: DefaultPromoDetailsViewModel =
    useViewModel(Valentines2024ViewModel.SYMBOL_DETAIL)

  return (
    <DefaultPromoDetailPage
      id="valentines-2024-detail"
      className="valentines-2024"
      viewModel={viewModel}
      imagePath={image('valentines/2024/header')}
    />
  )
}

const Valentines2024 = { Intro, Detail }

export default Valentines2024
