import LiveData from '../../liveData/LiveData'
import AsyncViewModel from '../AsyncViewModel'

interface BasePromoViewModel extends AsyncViewModel {
  readonly isAvailable: LiveData<boolean>
  init(): void
  proceed(): void
  close(): void
}

const BasePromoViewModel = {
  TAG_INIT: 'TAG_INIT',
}

export default BasePromoViewModel
