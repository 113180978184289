import React from 'react'
import AsyncIndicatorsView from '../../components/liveData/AsyncIndicatorsView'
import UnavailableContentModal from '../../components/unavailableContent/UnavailableContentModal'
import useLiveData from '../../liveData/useLiveData'
import { DO_NOTHING } from '../../utils/function'
import BasePromoViewModel from '../../viewModels/promo/BasePromoViewModel'

type BasePromoViewProps = {
  viewModel: BasePromoViewModel
}

const BasePromoView: React.FC<BasePromoViewProps> = ({
  viewModel,
}: BasePromoViewProps) => {
  const [isAvailable] = useLiveData(viewModel.isAvailable)
  return (
    <>
      <UnavailableContentModal
        isOpen={!isAvailable}
        onDidDismiss={DO_NOTHING}
      />
      <AsyncIndicatorsView
        controller={viewModel}
        tags={[BasePromoViewModel.TAG_INIT]}
      />
    </>
  )
}

export default BasePromoView
