import React from 'react'

export interface StringRendererProps {
  children: string
}

const StringRenderer: React.FC<StringRendererProps> = ({
  children,
// eslint-disable-next-line react/no-danger
}: StringRendererProps) => <span dangerouslySetInnerHTML={{ __html: children }} />

export default StringRenderer
