export const DO_NOTHING = () => {}

export function cast<T>(): (...args: any[]) => T {
  return (arg: any) => arg as T
}

export type PromiseMetadata<T> = {
  resolve: (value?: T | PromiseLike<T> | undefined) => void,
  reject: (error: Error) => void
}

export function forceUnwrap<T>(value: T | undefined | null): T {
  return value!
}

export type LazyValueAsyncFunction<T> = {
  (): Promise<T>
  currentValue?: T
}

export function lazyValueAsync<T>(getter: () => Promise<T> | T): LazyValueAsyncFunction<T> {
  let value: T | undefined
  const lazy: LazyValueAsyncFunction<T> = async () => {
    value = value ?? await getter()
    lazy.currentValue = value
    return value
  }
  return lazy
}
