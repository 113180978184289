import { inject, injectable } from 'inversify'
import BaseProduct from '../../plainTypes/billing/BaseProduct'
import Purchase from '../../plainTypes/billing/Purchase'
import SubscriberType from '../../plainTypes/user/SubscriberType'
import StorePlugin, { BuyResult } from '../../plugins/store/StorePlugin'
import BillingService from '../../services/billing/BillingService'
import BillingRepository from './BillingRepository'

@injectable()
export default class BillingRepositoryImpl implements BillingRepository {
  @inject(BillingService.SYMBOL)
  private readonly service: BillingService

  @inject(StorePlugin.SYMBOL)
  private readonly storePlugin: StorePlugin

  getSubscriptions = (ofType: SubscriberType) => this.service.getSubscriptions(ofType)
    .then((subs: BaseProduct[]) => this.getProductsData(
      subs.map(({ sku }) => sku), subs.find((s) => s.highlight)?.sku,
    ))

  getProductsData = (
    skus: string[], highlightedSKU?: string,
  ) => this.storePlugin.getProductsData(skus, highlightedSKU)

  buy = async (sku: string) => {
    // lbaglie: the method returns a non-optional, however, older versions of the
    // apps do not return anything
    const result: BuyResult | undefined = await this.storePlugin.buy(sku)
    return { isRestore: result?.isRestore ?? false }
  }

  restorePurchases = async () => {
    const restored: Purchase[] = await this.storePlugin.restore()
    return { didRestore: restored.length > 0 }
  }
}
