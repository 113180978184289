import LiveData from '../../liveData/LiveData'
import Trigger from '../../liveData/Trigger'
import AsyncController from '../AsyncController'

export enum RestoreDialogState {
  NONE,
  RESTORED,
  NOTHING_TO_RESTORE
}

interface RestoreController extends AsyncController {
  readonly dialogState: LiveData<RestoreDialogState>
  readonly restoredPurchases: Trigger

  restore(): void
  confirmRestoration(): void
}

const RestoreController = {
  SYMBOL: Symbol.for('RestoreController'),
  TAG_RESTORE: 'TAG_RESTORE',
}

export default RestoreController
