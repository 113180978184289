/* eslint-disable import/prefer-default-export */
export function cssModify(
  cssClass: string, ifCondition: boolean | undefined | null, thenModifyWith: string,
  elseModifyWith?: string,
): string {
  const fixdElse = elseModifyWith ? `--${elseModifyWith}` : ''
  return cssClass + (ifCondition ? `--${thenModifyWith}` : fixdElse)
}

export function cssFade(inCondition: boolean | undefined | null): string {
  return cssModify('app-animated-fade', inCondition, 'in', 'out')
}
