import { injectable } from 'inversify'
import Promo from '../../plainTypes/promo/Promo'
import PromoName from '../../plainTypes/PromoName'
import BaseParseService from '../BaseParseService'
import PromoService from './PromoService'

@injectable()
export default class PromoParseService extends BaseParseService implements PromoService {
  checkForPromo = (promoName?: PromoName) => this.runCloudCode<Promo>(
    'checkForAnyPromo', { promoName },
  )
}
