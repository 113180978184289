
interface SessionPlugin {
  acquireSession(): Promise<string | undefined>
}

const SessionPlugin = {
  SYMBOL: Symbol.for('SessionPlugin'),
}

export default SessionPlugin
