import { injectable } from 'inversify'
import BaseProduct from '../../plainTypes/billing/BaseProduct'
import SubscriberType, { SubscriberTypeEx } from '../../plainTypes/user/SubscriberType'
import BaseParseService from '../BaseParseService'
import BillingService from './BillingService'

@injectable()
export default class BillingParseService extends BaseParseService
  implements BillingService {
  getSubscriptions = async (ofType: SubscriberType) => {
    if (!SubscriberTypeEx.isPayer(ofType)) {
      return []
    }

    return this.runCloudCode<BaseProduct[]>('getSubscriptionIds')
  }
}
