enum AppErrorCode {
  UNKNOWN = -1,

  CODE_TWILIO_INVALID_PHONE_NUMBER = 21211,
  CODE_TWILIO_UNVERIFIED_PHONE_NUMBER = 21608,
  FAILED_TO_CONNECT = 100,
  OBJECT_NOT_FOUND = 101,
  USERNAME_MISSING = 200,
  PASSWORD_MISSING = 201,
  USERNAME_TAKEN = 202,
  SESSION_MISSING = 206,
  INVALID_USER = 209,
  CODE_PHONE_ALREADY_REGISTERED = 1001,
  INVALID_PARAMETERS = 1002,
  NOT_ALLOWED_TO_SEND_SIGNUP_SMS = 1018,
  CODE_PHONE_NOT_REGISTERED = 1020,
  INVALID_SMS_VERIFICATION_CODE = 1021,
  NOT_ALLOWED_TO_SEND_FORGOT_PASSWORD_SMS = 1022,
  PHONE_BLACKLISTED = 1023,
  USER_IS_NOT_BLOCKED = 1024,
  NO_CHAT_OPEN_FOR_IDS = 1025,
  NO_CHAT_USER_IDS = 1026,
  CANT_BLOCK_YSOS_USER = 1027,
  USER_IS_BLOCKED = 1028,
  PROFILE_ALREADY_FILLED = 1029,
  NO_LIKE_FOUND = 1032,
  DEPLETED_LIKES = 1033,
  SUBSCRIPTION_EXPIRED = 1034,
  ASSINET_INTERNAL_ERROR = 1035,
  SMS_LIMIT_REACHED = 1037,
  ACCESS_DENIED = 1038,

  APP_INVALID_CREDENTIALS = 10001,
  APP_PERMISSION = 10002,
  APP_NO_ACCOUNT = 10003,
  APP_TIMEOUT = 10004,
  APP_PERMISSION_DENIED_CAMERA = 10005,
  APP_COULDNT_CONNECT_STORE = 10006,
  APP_COULDNT_CONNECT_STORE_RESTORE = 10007,
  APP_PUSH_NO_PERMISSION = 10008,
  APP_GEOLOCATION_NO_PERMISSION = 10009,
  APP_GEOLOCATION_PROBLEM = 10010,
  APP_INAPPBROWSER_ALREADY_ACTIVE = 10011,
  APP_PRODUCT_ALREADY_OWNED = 10012
}

export default AppErrorCode
