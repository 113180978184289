import { inject, injectable } from 'inversify'
import WebViewParentPlugin, { WebViewParentPluginEx } from '../webViewParent/WebViewParentPlugin'
import SessionPlugin from './SessionPlugin'

enum SessionEvent {
  GET_SESSION = 'session'
}

@injectable()
export default class WebViewParentSessionPlugin implements SessionPlugin {
  @inject(WebViewParentPlugin.SYMBOL)
  private parent: WebViewParentPlugin

  acquireSession = () => WebViewParentPluginEx.callAndWait<
    { session: string | undefined }, string | undefined
  >(
    this.parent,
    SessionEvent.GET_SESSION,
    undefined,
    ({ session }) => session,
  )
}
