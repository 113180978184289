import { IonReactRouter } from '@ionic/react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import 'reflect-metadata'
import App from './App'
import Configurations from './Configurations'
import ParseApiInitializer from './services/utils/parse/ParseApiInitializer'
import * as serviceWorker from './serviceWorker'

Configurations.initialize()
  .then(() => {
    ParseApiInitializer.init()
    ReactDOM.render(
      <IonReactRouter>
        <App />
      </IonReactRouter>, document.getElementById('root'),
    )
  })


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
