/* eslint-disable no-shadow */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable semi */
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import React from "react";
import PurchaseController, {
  PurchaseDialogState,
} from "../../controllers/billing/PurchaseController";
import RestoreController from "../../controllers/billing/RestoreController";
import { bindReset, bindToValue } from "../../liveData/bindings";
import useLiveData from "../../liveData/useLiveData";
import useLocalization from "../../plugins/localization/useLocalization";
import GoldCongratsAlert from "../billing/GoldCongratsAlert";
import RestoreView from "../billing/RestoreView";
import ClickableAnchor from "../clickable/ClickableAnchor";
import ClickableDiv from "../clickable/ClickableDiv";
import { cssModify } from "../css";
import EULADisclaimer from "../eulaDisclaimer/EULADisclaimer";
import AsyncIndicatorsView from "../liveData/AsyncIndicatorsView";
import "./PurchaseModal.scss";

export type PurchaseModalProps = {
  isOpen: boolean;
  onDidDismiss?: (e: any) => void;
  message?: string;
  purchaseController: PurchaseController;
  restoreController: RestoreController;
};

const PurchaseModal: React.FC<PurchaseModalProps> = ({
  isOpen,
  onDidDismiss,
  message,
  purchaseController,
  restoreController,
}: PurchaseModalProps) => {
  const { strings } = useLocalization();

  const [purchaseDialog] = useLiveData(purchaseController.dialogState);

  const { selectionController } = purchaseController;
  const [products] = useLiveData(selectionController.items);
  const selectedProduct = selectionController.selectedItem.hasValue;

  function renderOffers() {
    if (
      window.navigator.userAgent.toLowerCase().includes("android") &&
      products.length
    ) {
      return (
        <>
          <div className="purchase__item">
            <ClickableDiv
              className={cssModify(
                "purchase__item__content",
                products[0].selected,
                "selected"
              )}
              onClick={bindToValue(
                purchaseController.selectSubscription,
                products[0].item
              )}
            >
              <div className="purchase__item__content__price">
                {strings.template_label_purchase_period(
                  products[0].item.product.billingPeriod!
                )}
                <br />
                R$ 29,90
              </div>
              <div className="purchase__item__content__reference">
                R$ 29,90/mês
              </div>
            </ClickableDiv>
          </div>

          <div className="purchase__item">
            <div className="purchase__item__highlight">
              <IonText color="veryLight">
                {strings.label_purchase_highlight}
              </IonText>
            </div>

            <ClickableDiv
              className={cssModify(
                "purchase__item__content",
                products[1].selected,
                "selected"
              )}
              onClick={bindToValue(
                purchaseController.selectSubscription,
                products[1].item
              )}
            >
              <div className="purchase__item__content__price">
                {strings.template_label_purchase_period(
                  products[1].item.product.billingPeriod!
                )}
                <br />
                R$ 119,90
              </div>
              <div className="purchase__item__content__reference">
                R$ 19,98/mês
              </div>
              <div className="purchase__item__content__discount">
                <IonText color="veryLight">33% desconto</IonText>
              </div>
            </ClickableDiv>
          </div>
        </>
      );
    }

    return products.map(({ item, selected }) => (
      <div key={item.product.sku} className="purchase__item">
        {item.highlight ? (
          <div className="purchase__item__highlight">
            <IonText color="veryLight">
              {strings.label_purchase_highlight}
            </IonText>
          </div>
        ) : null}
        <ClickableDiv
          className={cssModify("purchase__item__content", selected, "selected")}
          onClick={bindToValue(purchaseController.selectSubscription, item)}
        >
          <div className="purchase__item__content__price">
            {strings.template_label_purchase_period(
              item.product.billingPeriod!
            )}
            <br />
            {item.fullPrice}
          </div>
          <div className="purchase__item__content__reference">
            {item.referencePrice}
          </div>
          {item.discount ? (
            <div className="purchase__item__content__discount">
              <IonText color="veryLight">{item.discount}</IonText>
            </div>
          ) : null}
        </ClickableDiv>
      </div>
    ));
  }

  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
        cssClass="purchase-modal"
      >
        <IonHeader className="header-borderless">
          <IonToolbar color="veryLight">
            <IonButtons slot="start">
              <IonButton onClick={onDidDismiss} color="primary">
                <IonIcon icon={chevronDown} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent color="veryLight">
          <IonText color="textOnBackground">
            <div className="purchase">
              <div className="purchase__title">{strings.title_purchase}</div>
              <div className="purchase__message">{message}</div>
              <div className="purchase__item-container">{renderOffers()}</div>
              {selectedProduct ? (
                <IonButton
                  onClick={purchaseController.subscribeToSelected}
                  color="secondary"
                  className="purchase__button"
                >
                  {strings.button_purchase}
                </IonButton>
              ) : undefined}
              <ClickableAnchor
                className="purchase__restore"
                onClick={restoreController.restore}
              >
                {strings.item_settings_restore}
              </ClickableAnchor>
              <EULADisclaimer
                color="textOnBackground"
                className="purchase__disclaimer"
                textComponents={strings.terms_eula.components}
                tosIndex={strings.terms_eula.tosIndex}
                privacyIndex={strings.terms_eula.privacyIndex}
              />
            </div>
          </IonText>
        </IonContent>
      </IonModal>
      <RestoreView controller={restoreController} />
      <GoldCongratsAlert
        isOpen={
          purchaseDialog === PurchaseDialogState.SUBSCRIBED ||
          purchaseDialog === PurchaseDialogState.SUBSCRIBED_RESTORED
        }
        isRestored={purchaseDialog === PurchaseDialogState.SUBSCRIBED_RESTORED}
        onDidDismiss={bindReset(purchaseController.dialogState)}
        onContinue={purchaseController.finish}
      />
      <AsyncIndicatorsView
        controller={purchaseController}
        tags={[PurchaseController.TAG_INIT, PurchaseController.TAG_PURCHASE]}
      />
    </>
  );
};

export default PurchaseModal;
