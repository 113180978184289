/* eslint-disable import/prefer-default-export */
import ResourceType from './ResourceType'
import { combine } from '../path'
import { contains } from '../array'

export function resource(ofType: ResourceType, withPath: string): string {
  return combine('/assets', ofType.toString(), withPath)
}

export function image(withPath: string): string {
  return resource(
    ResourceType.IMAGE,
    `${withPath}${contains(withPath, '.') ? '' : '.svg'}`,
  )
}
