import React from 'react'
import useLocalization from '../../plugins/localization/useLocalization'
import { image } from '../../utils/resources/resourceUtils'
import SpecialAlert from '../alert/SpecialAlert'
import './GoldCongratsAlert.scss'

export type GoldCongratsAlertProps = {
  isOpen: boolean
  isRestored?: boolean
  onDidDismiss: (args: any) => void
  onContinue?: () => void
}

const GoldCongratsAlert: React.FC<GoldCongratsAlertProps> = ({
  isOpen, isRestored, onDidDismiss, onContinue,
}: GoldCongratsAlertProps) => {
  const { strings } = useLocalization()
  return (
    <SpecialAlert
      key="gold-congrats_"
      backdropDismiss={false}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      header={strings.dialog_title_goldcongrats}
      message={`
        <div class="gold-congrats">
            <img
              src="${image('gold/icon_subscribed')}"
              class="gold-congrats__image"
              alt="gold"
            />
            <div class="gold-congrats__subtitle">
              ${strings.dialog_subtitle_goldcongrats}
            </div>
            <div class="gold-congrats__message">
              ${isRestored ? strings.dialog_message_goldcongrats_restore : strings.dialog_message_goldcongrats}
            </div>
        </div>
      `}
      button={{ text: strings.continue, handler: onContinue }}
    />
  )
}

export default GoldCongratsAlert
