import InjectableType from '../../dependencyInjection/InjectableType'
import { LazyValueAsyncFunction } from '../../utils/function'

export enum Platform {
  IOS = 'ios',
  ANDROID = 'android',
  OTHER = 'OTHER'
}

interface RuntimePlugin {
  platform: LazyValueAsyncFunction<Platform>
}

const RuntimePlugin: InjectableType = {
  SYMBOL: Symbol.for('RuntimePlugin'),
}

export default RuntimePlugin
