import React from 'react'
import { Container } from 'inversify'
import ReactIoCContainer from './inversify.config.react'

export interface IoC {
  readonly container: Container
}

class IoCImpl implements IoC {
  constructor(public readonly container: Container) { }
}

const IoCContext = React.createContext<IoC>(
  new IoCImpl(ReactIoCContainer),
)
export default IoCContext

export const IoCContextProvider: React.FC<{ iocContainer?: Container }> = ({
  iocContainer, children,
}: React.PropsWithChildren<{ iocContainer?: Container }>) => {
  const ioc = new IoCImpl(iocContainer ?? ReactIoCContainer)
  return (
    <IoCContext.Provider value={ioc}>
      {children}
    </IoCContext.Provider>
  )
}
