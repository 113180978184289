/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import './Clickable.scss'

export type ClickableAnchorProps = {
  className?: string
  onClick?: () => void
}

const ClickableAnchor: React.FC<ClickableAnchorProps> = ({
  children, className, onClick,
}: React.PropsWithChildren<ClickableAnchorProps>) => (
  <a
    className={`clickable-anchor ${className}`}
    tabIndex={-100}
    role="button"
    onClick={onClick}
  >
    {children}
  </a>
)

export default ClickableAnchor
