import { inject, injectable } from 'inversify'
import { isNil, isNumber } from 'lodash'
import Localization from '../plugins/localization/Localization'
import AppError from './AppError'
import AppErrorCode from './AppErrorCode'
import ErrorParser from './ErrorParser'

@injectable()
export default class ErrorParserImpl implements ErrorParser {
  @inject(Localization.SYMBOL)
  private readonly localization: Localization

  parse = (error: any) => {
    console.log('Error', error)

    const { strings } = this.localization

    const fixdError = isNumber(error)
      ? new AppError(error)
      : error

    if (isNil(fixdError.code)) {
      return new AppError(undefined, strings.error_generic)
    }

    let code: AppErrorCode
    let message: string
    try {
      code = fixdError.code as AppErrorCode
      message = strings.error_app_code_option(code)
    } catch (_ex) {
      code = AppErrorCode.UNKNOWN
      message = strings.error_generic
    }
    return new AppError(code, message)
  }
}
