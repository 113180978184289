/* eslint-disable import/prefer-default-export */
import { isString } from 'lodash'
import Gender, { GenderEx } from '../../../plainTypes/Gender'
import { contains } from '../../../utils/array'
import when from '../../../utils/when'

export function translateOptions<T>(
  ...translations: Array<[T, string]>
): (val: T) => string;
export function translateOptions<T>(
  translations: Array<[T, string]>, fallback: string,
): (val: T) => string;
export function translateOptions<T>(...args: any[]): (val: T) => string {
  if (isString(args[1])) {
    const [translations, fallback] = args
    return (value: T) => when(value, translations.concat([value, fallback]))
  }

  return (value: T) => when(value, args.concat([value, 'ssssss']))
}

export function translateMaritalStatus(
  coupleText: string, singleText: string,
): (g: Gender) => string {
  return (gender: Gender) => (GenderEx.isCouple(gender)
    ? coupleText
    : singleText)
}

export function translateOwnership(
  yoursText: string, hisText: string, hersText: string,
): (gender: Gender, idx: number) => string {
  return (gender: Gender, idx: number) => when(GenderEx.isCouple(gender), [
    [false, yoursText],
    [true, when(idx, [
      [0, gender === Gender.COUPLE_MAN_WOMAN ? hisText : yoursText],
      [idx, gender === Gender.COUPLE_MAN_MAN ? hisText : hersText],
    ])],
  ])
}

export function translateTemplate(template: string): (...args: any[]) => string {
  return (...args: any[]) => {
    let fixed = template
    args.forEach((arg, idx) => {
      const key = `{${idx}}`
      while (contains(fixed, key)) {
        fixed = fixed.replace(`{${idx}}`, arg)
      }
    })
    return fixed
  }
}
