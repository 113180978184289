import React from 'react'
import { image } from '../../utils/resources/resourceUtils'
import DefaultPromoDetailsViewModel from '../../viewModels/promo/DefaultPromoDetailsViewModel'
import useViewModel from '../../viewModels/useViewModel'
import WelcomeOfferViewModel from '../../viewModels/welcome/WelcomeOfferViewModel'
import DefaultPromoDetailPage from '../promo/DefaultPromoDetailPage'
import './WelcomeOffer.scss'

const Detail: React.FC = () => {
  const viewModel: DefaultPromoDetailsViewModel =
    useViewModel(WelcomeOfferViewModel.SYMBOL_DETAIL)

  return (
    <DefaultPromoDetailPage
      id="welcome-offer-detail"
      className="welcome-offer"
      viewModel={viewModel}
      imagePath={image('welcome/header')}
    />
  )
}

const WelcomeOffer = { Detail }

export default WelcomeOffer
