import React from 'react'
import { image } from '../../utils/resources/resourceUtils'
import CyberMonday2021ViewModel from '../../viewModels/cybermonday/2021'
import DefaultPromoDetailsViewModel from '../../viewModels/promo/DefaultPromoDetailsViewModel'
import DefaultPromoIntroViewModel from '../../viewModels/promo/DefaultPromoIntroViewModel'
import useViewModel from '../../viewModels/useViewModel'
import DefaultPromoDetailPage from '../promo/DefaultPromoDetailPage'
import DefaultPromoIntroPage from '../promo/DefaultPromoIntroPage'
import './2021.scss'

const Intro: React.FC = () => {
  const viewModel: DefaultPromoIntroViewModel = useViewModel(CyberMonday2021ViewModel.SYMBOL_INTRO)

  return (
    <DefaultPromoIntroPage
      id="cybermonday-2021-intro"
      className="cybermonday-2021"
      viewModel={viewModel}
      titleImage={image('cyberMonday/2021/header')}
    />
  )
}

const Detail: React.FC = () => {
  const viewModel: DefaultPromoDetailsViewModel =
    useViewModel(CyberMonday2021ViewModel.SYMBOL_DETAIL)

  return (
    <DefaultPromoDetailPage
      id="cybermonday-2021-detail"
      className="cybermonday-2021"
      viewModel={viewModel}
      imagePath={image('cyberMonday/2021/header')}
    />
  )
}

const CyberMonday2021 = { Intro, Detail }

export default CyberMonday2021
