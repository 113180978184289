import { inject, injectable } from 'inversify'
import Parse from 'parse'
import Configurations from '../Configurations'
import AppError from '../errors/AppError'
import AppErrorCode from '../errors/AppErrorCode'
import RuntimePlugin from '../plugins/runtime/RuntimePlugin'
import SessionPlugin from '../plugins/session/SessionPlugin'

// lbaglie: gotta be handled here, because parse won't return on timeout (WTF).
const DEFAULT_TIMEOUT_MILLIS = 60 * 1000

@injectable()
export default abstract class BaseParseService {
  @inject(RuntimePlugin.SYMBOL)
  private readonly runtime: RuntimePlugin

  @inject(SessionPlugin.SYMBOL)
  private readonly session: SessionPlugin

  protected readonly runCloudCode = async <T = any>(
    name: string, params: {} = {},
  ): Promise<T> => {
    await this.becomeUserOfParent()

    return new Promise((resolve, reject) => {
      let returned = false
      let timeoutToken: NodeJS.Timeout
      const callBack = (action: () => void) => {
        if (!returned) {
          action()
          returned = true
          clearTimeout(timeoutToken)
        }
      }

      this.runtime.platform().then((platform) => Parse.Cloud.run(
        name,
        {
          ...params,
          platform,
        },
      ))
        .then((result) => callBack(() => resolve(result)))
        .catch((ex) => callBack(() => reject(ex)))

      timeoutToken = setTimeout(
        () => {
          callBack(() => reject(new AppError(AppErrorCode.APP_TIMEOUT)))
        },
        DEFAULT_TIMEOUT_MILLIS,
      )
    })
  }

  private becomeUserOfParent = async () => {
    const key = `Parse/${Configurations.parse.APP_ID}/currentUser`
    const session: string | undefined = await this.session.acquireSession()

    if (!session) {
      localStorage.removeItem(key)
      return
    }

    if (localStorage.getItem(key) === session) {
      return
    }

    try {
      JSON.parse(session) // lbaglie: fails if not object string
      localStorage.setItem(key, session)
    } catch (e) {
      await Parse.User.become(session)
    }
  }
}
