import InjectableType from '../dependencyInjection/InjectableType'
import PromoName from '../plainTypes/PromoName'

export type NavigatorOptions = {
  replaces?: boolean
  fromPath?: string
  useLocation?: boolean
}

interface Navigator {
  promoDetail(name: PromoName, options?: NavigatorOptions): void

  back(): void
  close(afterPurchasing?: boolean): void
  getQuery<T>(): T
  isIn(path: string): boolean
}

const Navigator: InjectableType = {
  SYMBOL: Symbol.for('Navigator'),
}

export default Navigator
