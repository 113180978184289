import { inject, injectable } from 'inversify'
import PurchaseController from '../../controllers/billing/PurchaseController'
import RestoreController from '../../controllers/billing/RestoreController'
import LiveDataImpl from '../../liveData/LiveDataImpl'
import Promo from '../../plainTypes/promo/Promo'
import PromoName from '../../plainTypes/PromoName'
import SimpleBrowserPlugin from '../../plugins/browser/SimpleBrowserPlugin'
import CompositeDisposable from '../../utils/disposasble/CompositeDisposable'
import Disposable, { DisposableEx } from '../../utils/disposasble/Disposable'
import DefaultPromoDetailsViewModel, { DefaultPromoDetailDialogState } from './DefaultPromoDetailsViewModel'
import BasePromoViewModelImpl from './BasePromoViewModelImpl'

@injectable()
export default class DefaultPromoDetailsViewModelImpl extends BasePromoViewModelImpl
  implements DefaultPromoDetailsViewModel {
  @inject(SimpleBrowserPlugin.SYMBOL)
  protected readonly browser: SimpleBrowserPlugin

  @inject(PurchaseController.SYMBOL)
  readonly purchaseController: PurchaseController

  @inject(RestoreController.SYMBOL)
  readonly restoreController: RestoreController

  readonly promoName: PromoName

  readonly dialogState = new LiveDataImpl(DefaultPromoDetailDialogState.NONE)

  readonly messages = new LiveDataImpl({
    main: '', sub: '', purchase: '', button: '',
  })

  private disposable: Disposable

  initPromo(promo: Promo) {
    this.disposable = new CompositeDisposable(
      this.purchaseController.couldNotLoad.observe(
        () => this.dialogState.set(DefaultPromoDetailDialogState.NO_STORE),
      ),
      this.purchaseController.purchased.observe(() => this.navigator.close(true)),
      this.restoreController.restoredPurchases.observe(() => this.navigator.close(true)),
    )

    this.purchaseController.init(promo.products)

    const { strings, formatLineBreaks } = this.localization
    this.runtime.platform().then((platform) => this.messages.set({
      main: formatLineBreaks(strings.template_message_promo_detail(platform)),
      sub: strings.template_subtitle_promo_detail(promo.value, 'BRL'),
      purchase: strings.template_message_purchase(promo.value, 'BRL', platform),
      button: strings.template_button_promo_detail(promo.value, 'BRL'),
    }))
  }

  deinit = () => {
    DisposableEx.safeDispose(this.disposable)
    this.purchaseController.deinit()
    this.restoreController.deinit()
    this.deinitFun()
  }

  back = () => this.navigator.back()

  proceed = () => this.dialogState.set(DefaultPromoDetailDialogState.PURCHASE)
}
