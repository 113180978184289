import { isDate } from 'lodash'

enum OrderComparison {
  PRECEDES = -1,
  SAME = 0,
  FOLLOWS = 1
}

export default OrderComparison

export function compare(value: string, to: string): OrderComparison
export function compare(value: number, to: number): OrderComparison
export function compare(value: Date, to: Date): OrderComparison
export function compare(
  value: number | string | Date, to: number | string | Date,
): OrderComparison {
  if (isDate(value)) {
    return compare(value.getTime(), (to as Date).getTime())
  }

  if (value < to) {
    return OrderComparison.PRECEDES
  } if (value > to) {
    return OrderComparison.FOLLOWS
  }
  return OrderComparison.SAME
}
