import { last } from 'lodash'

export function ensureNoPrecedingSlash(component: string): string {
  const slashRegex = /^\//g
  return component.replace(slashRegex, '')
}

export function combine(...components: string[]): string {
  return `${components[0]}/${components.slice(1).map((c) => ensureNoPrecedingSlash(c)).join('/')}`
}

export function fileName(name: string, extension: string): string {
  return `${name}.${extension}`
}

export function lastPathComponent(path: string): string {
  return last(path.split('/'))!
}
