/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable lines-between-class-members */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable semi */

import { round } from "lodash";
import AppErrorCode from "../../../errors/AppErrorCode";
import BillingPeriod from "../../../plainTypes/billing/BillingPeriod";
import { DateUnit } from "../../../utils/date";
import { formatCurrency } from "../../../utils/formatting/currency";
import { formatDayMonth } from "../../../utils/formatting/date";
import when from "../../../utils/when";
import { Platform } from "../../runtime/RuntimePlugin";
import { translateOptions, translateTemplate } from "./utils";

export default class LocalizedStrings {
  // common
  app_name = "ysos";
  ok = "OK";
  yes = "Sim";
  no = "Não";
  not_now = "Não agora";
  save = "Salvar";
  cancel = "Cancelar";
  close = "Fechar";
  signup = "Cadastrar";
  enter = "Entrar";
  continue = "Continuar";
  attention = "Atenção";
  confirm = "Confirmar";
  alter = "Alterar";
  back = "Voltar";
  you = "você";
  understood = "Entendi";
  format_date = "DD/MM/YYYY";
  sexualorientation = "Orientação Sexual";
  password = "Senha";
  dismiss = "Descartar";
  select = "Selecionar";
  delete = "Excluir";
  try_again = "Tentar novamente";
  stay = "Ficar";
  exit = "Sair";
  separator_styled = " • ";
  dialog_title_nocamerapermission = "Sem acesso a câmera";
  dialog_message_nocamerapermission =
    'Para enviar fotos é necessário liberar a permissão de acesso à câmera.\n\nLibere o acesso a câmera em <span class="app-text--bold">Ajustes &gt; Ysos &gt; Câmera</span>';
  dialog_button_nocamerapermission = "Ir para ajustes";
  template_commentary_addendum = (remaining: number) =>
    remaining > 0 ? `Digite mais ${remaining} caracter(es) para continuar` : "";
  template_distance = (value: number, unit: "km" | "m" | "cm" = "km") =>
    `${round(value)}${unit}`;
  platform_store_option = translateOptions(
    [Platform.ANDROID, "Play Store"],
    [Platform.IOS, "App Store"],
    [Platform.OTHER, "Tela de detalhes da assinatura"]
  );
  terms_eula = {
    components: ["Termos de uso", " e ", "política de privacidade"],
    tosIndex: 0,
    privacyIndex: 2,
  };
  dialog_title_nostore = "Loja indisponível";
  dialog_message_nostore =
    "Aparentemente o dispositivo não tem acesso a loja da plataforma.";

  // gold congrats
  dialog_title_goldcongrats = "Seu perfil é gold!";
  dialog_subtitle_goldcongrats = "Parabéns!";
  dialog_message_goldcongrats =
    "Seu perfil agora é Gold, aproveite e comece a curtir!";
  dialog_message_goldcongrats_restore =
    "Sua assinatura foi recuperada para este perfil, aproveite e comece a curtir!";

  // purchase controller
  title_purchase = "Escolha o plano para renovação após o 1º mês.";
  label_purchase_highlight = "Melhor opção";
  template_message_purchase = (
    price: number,
    currencyCode: string,
    platform: Platform
  ) =>
    `${formatCurrency(
      price,
      currencyCode
    )} no primeiro mês. Renovação de acordo com o plano escolhido. Cancele a renovação a qualquer momento na ${this.platform_store_option(
      platform
    )}.`;
  template_label_purchase_period = (billingPeriod: BillingPeriod) =>
    `${when(billingPeriod, [
      [BillingPeriod.ONE_WEEK, "7 dias"],
      [BillingPeriod.ONE_MONTH, "1 mês"],
      [BillingPeriod.THREE_MONTHS, "3 meses"],
      [BillingPeriod.SIX_MONTHS, "6 meses"],
    ]).toUpperCase()} por`;
  template_message_purchase_reference = (price: number, currencyCode: string) =>
    ` ${formatCurrency(price, currencyCode)}/mês`;
  template_message_purchase_discount = translateTemplate("{0}% desconto");
  button_purchase = "Aproveitar a promoção";

  // common
  subtitle_promo_intro_chance = "Sua chance de ser gold";
  subtitle_promo_intro_discount = "80% de desconto";
  template_promo_intro_end = (date: Date | undefined) =>
    date ? `Promoção válida até ${formatDayMonth(date)}` : "";
  template_message_promo_intro = (price: number, currencyCode: string) =>
    `<span class="app-text--bold">Chega de esperar para curtir!</span>\nTodas as vantagens exclusivas do Gold para você por apenas <span class="app-text--bold promo__price">${formatCurrency(
      price,
      currencyCode
    )} no primeiro mês.</span>`;
  button_promo_intro = "Quero ser gold!";
  template_message_promo_detail = (platform: Platform) =>
    `Após o 1º mês escolha o plano de renovação.\nCancele quando quiser na ${this.platform_store_option(
      platform
    )}.`;
  items_promo_detail = [
    "Ganhe prioridade e destaque em suas curtidas.",
    "Pedidos para ver galerias e Curtidas ilimitadas.",
    "Veja quem curtiu ou visitou o seu perfil.",
    "Ganhe +1 chance para enviar uma mensagem antes da combinação.",
  ];
  template_button_promo_detail = (price: number, currencyCode: string) =>
    `Assinar por ${formatCurrency(price, currencyCode)}`;
  button_disclaimer_promo_detail = "no primeiro mês";
  template_subtitle_promo_detail = (price: number, currencyCode: string) =>
    `TODAS as vantagens por ${formatCurrency(price, currencyCode)} no 1º mês.`;

  // unavailable content
  title_unavailablecontent = "Conteúdo indisponível no momento...";
  button_unavailablecontent = "Voltar";

  // utils
  date_unit_options = (unit: DateUnit, plural: boolean = false) =>
    plural
      ? when(unit, [
          [DateUnit.YEAR, "anos"],
          [DateUnit.MONTH, "meses"],
          [DateUnit.DAY, "dias"],
        ])
      : when(unit, [
          [DateUnit.YEAR, "ano"],
          [DateUnit.MONTH, "mês"],
          [DateUnit.DAY, "dia"],
        ]);
  template_date_unit = (elapsedDate: number, unit: DateUnit) =>
    `${elapsedDate} ${this.date_unit_options(unit, elapsedDate > 1)}`;

  // settings
  item_settings_restore = "Restaurar compras";
  dialog_title_settings_norestore = "Oops";
  dialog_message_settings_norestore =
    "Não há compras ativas para serem recuperadas.";

  // errors
  error_expired_session =
    "Sua sessão expirou. Faça login novamente ou entre em contato com o suporte!";
  error_generic =
    "Ops! Ocorreu um erro.\nTente novamente ou entre em contato com o suporte.";
  error_app_code_option = translateOptions(
    [
      [
        AppErrorCode.APP_INVALID_CREDENTIALS,
        "Login ou senha inválidos. Tente novamente!",
      ],
      [
        AppErrorCode.APP_PERMISSION,
        "O aplicativo não possui permissão para realizar a ação.",
      ],
      [
        AppErrorCode.APP_NO_ACCOUNT,
        "É necessário configurar uma conta de email para enviar emails ao ysos.",
      ],
      [
        AppErrorCode.APP_TIMEOUT,
        "Tempo limite estourado. Verifique conexão com internet.",
      ],
      [
        AppErrorCode.APP_PERMISSION_DENIED_CAMERA,
        "É necessário liberar permissão para a câmera no aplicativo Ajustes.",
      ],
      [AppErrorCode.APP_COULDNT_CONNECT_STORE, "Compra cancelada."],
      [
        AppErrorCode.APP_COULDNT_CONNECT_STORE_RESTORE,
        "Restauração cancelada.",
      ],
      [
        AppErrorCode.APP_GEOLOCATION_NO_PERMISSION,
        "O ysos não tem permissão para acessar sua localização.",
      ],
      [
        AppErrorCode.APP_GEOLOCATION_PROBLEM,
        "Ocorreu um erro ao tentar obter sua localização.",
      ],
      [AppErrorCode.APP_PRODUCT_ALREADY_OWNED, "Item já comprado."],

      [AppErrorCode.CODE_TWILIO_INVALID_PHONE_NUMBER, ""],
      [AppErrorCode.CODE_TWILIO_UNVERIFIED_PHONE_NUMBER, ""],
      [AppErrorCode.OBJECT_NOT_FOUND, "Objeto não encontrado."],
      [
        AppErrorCode.USERNAME_MISSING,
        "O nome de perfil não foi preenchido! Tente novamente ou entre em contato com o suporte.",
      ],
      [
        AppErrorCode.PASSWORD_MISSING,
        "A senha não foi preenchida! Tente novamente ou entre em contato com o suporte.",
      ],
      [
        AppErrorCode.USERNAME_TAKEN,
        "Já existe um cadastro com esse nome de perfil! Por favor, volte no cadastro de nome de perfil e escolha outro :(",
      ],
      [AppErrorCode.SESSION_MISSING, ""],
      [AppErrorCode.INVALID_USER, ""],
      [
        AppErrorCode.CODE_PHONE_ALREADY_REGISTERED,
        "Ops! Número já cadastrado.",
      ],
      [
        AppErrorCode.NOT_ALLOWED_TO_SEND_SIGNUP_SMS,
        "Ops! O envio do seu código já foi solicitado. O recebimento do SMS pode demorar algumas horas.",
      ],
      [
        AppErrorCode.CODE_PHONE_NOT_REGISTERED,
        "Ops! Seu número não está cadastrado.",
      ],
      [
        AppErrorCode.INVALID_SMS_VERIFICATION_CODE,
        "Código digitado incorretamente ou expirado :(",
      ],
      [
        AppErrorCode.NOT_ALLOWED_TO_SEND_FORGOT_PASSWORD_SMS,
        "Ops! O envio do seu código já foi solicitado. O recebimento do SMS pode demorar algumas horas.",
      ],
      [AppErrorCode.PHONE_BLACKLISTED, ""],
      [AppErrorCode.USER_IS_NOT_BLOCKED, ""],
      [AppErrorCode.USER_IS_BLOCKED, "O usuário não está mais disponível."],
      [AppErrorCode.NO_CHAT_OPEN_FOR_IDS, ""],
      [AppErrorCode.NO_CHAT_USER_IDS, ""],
      [
        AppErrorCode.CANT_BLOCK_YSOS_USER,
        "Não é possível bloquear o usuário ysos.",
      ],
      [
        AppErrorCode.FAILED_TO_CONNECT,
        "Não foi possível conectar ao ysos. Tente novamente mais tarde.",
      ],
      [AppErrorCode.PROFILE_ALREADY_FILLED, "O perfil já está preenchido"],
      [AppErrorCode.INVALID_PARAMETERS, ""],
      [AppErrorCode.NO_LIKE_FOUND, "A curtida não foi encontrada."],
      [
        AppErrorCode.DEPLETED_LIKES,
        "Suas curtidas diárias terminaram. Aguarde 24hs para continuar a curtir.",
      ],
      [
        AppErrorCode.SUBSCRIPTION_EXPIRED,
        "Não há assinaturas disponíveis no momento.",
      ],
      [
        AppErrorCode.ASSINET_INTERNAL_ERROR,
        "Falha no processamento. Por favor entre em contato com o suporte.",
      ],
      [
        AppErrorCode.SMS_LIMIT_REACHED,
        "Problemas para receber o SMS?\nEntre em contato com o suporte.",
      ],
      [
        AppErrorCode.ACCESS_DENIED,
        "Seu perfil foi temporariamente bloqueado! Entre em contato com o suporte para saber mais.",
      ],
    ],
    "Ops! Ocorreu um erro.\nTente novamente ou entre em contato com o suporte."
  );
}
