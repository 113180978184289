import { injectable } from 'inversify'
import LiveDataImpl from '../../liveData/LiveDataImpl'
import Promo from '../../plainTypes/promo/Promo'
import PromoName from '../../plainTypes/PromoName'
import BasePromoViewModelImpl from './BasePromoViewModelImpl'
import DefaultPromoIntroViewModel from './DefaultPromoIntroViewModel'

@injectable()
export default class DefaultPromoIntroViewModelImpl
  extends BasePromoViewModelImpl
  implements DefaultPromoIntroViewModel {
  readonly promoName: PromoName

  readonly messages = new LiveDataImpl({ disclaimer: '', period: '', value: '' })

  initPromo = (promo: Promo) => this.runtime.platform()
    .then((platform) => {
      const { strings, formatLineBreaks } = this.localization
      this.messages.set({
        disclaimer: strings.template_message_purchase(promo.value, 'BRL', platform),
        period: strings.template_promo_intro_end(promo.period?.end),
        value: formatLineBreaks(strings.template_message_promo_intro(promo.value, 'BRL')),
      })
    })

  proceed = () => this.navigator.promoDetail(this.promoName)
}
