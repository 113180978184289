import { inject, injectable } from 'inversify'
import LiveDataImpl from '../../liveData/LiveDataImpl'
import TriggerImpl from '../../liveData/TriggerImpl'
import BillingRepository from '../../repositories/billing/BillingRepository'
import AsyncControllerImpl from '../AsyncControllerImpl'
import RestoreController, { RestoreDialogState } from './RestoreController'

@injectable()
export default class RestoreControllerImpl
  extends AsyncControllerImpl
  implements RestoreController {
  @inject(BillingRepository.SYMBOL)
  private readonly billingRepository: BillingRepository

  readonly dialogState = new LiveDataImpl(RestoreDialogState.NONE)

  readonly restoredPurchases = new TriggerImpl()

  restore = () => this.performAsyncSafe(
    async () => {
      const { didRestore } = await this.billingRepository.restorePurchases()

      this.dialogState.set(
        didRestore
          ? RestoreDialogState.RESTORED
          : RestoreDialogState.NOTHING_TO_RESTORE,
      )
    },
    RestoreController.TAG_RESTORE,
  )

  confirmRestoration = () => this.restoredPurchases.fire()
}
