import BaseProduct from '../../plainTypes/billing/BaseProduct'
import SubscriberType from '../../plainTypes/user/SubscriberType'

interface BillingService {
  getSubscriptions(ofType: SubscriberType): Promise<BaseProduct[]>
}

const BillingService = {
  SYMBOL: Symbol.for('BillingService'),
}

export default BillingService
