import { DO_NOTHING } from '../function'

export default interface Disposable {
  dispose(): void
}

export const DisposableEx = {
  safeDispose: (...disposables: (Disposable | undefined | null)[]) => {
    disposables.forEach((disposable) => {
      if (disposable) {
        disposable.dispose()
      }
    })
  },
  null: {
    dispose: DO_NOTHING,
  } as Disposable,
}
