import PurchaseController from '../../controllers/billing/PurchaseController'
import RestoreController from '../../controllers/billing/RestoreController'
import LiveData from '../../liveData/LiveData'
import LiveDataImpl from '../../liveData/LiveDataImpl'
import BasePromoViewModel from './BasePromoViewModel'

export enum DefaultPromoDetailDialogState {
  NONE,
  PURCHASE,
  NO_STORE
}

export default interface DefaultPromoDetailsViewModel extends BasePromoViewModel {
  readonly purchaseController: PurchaseController
  readonly restoreController: RestoreController

  readonly dialogState: LiveData<DefaultPromoDetailDialogState>
  readonly messages: LiveDataImpl<{
    main: string
    sub: string
    purchase: string
    button: string
  }>

  back(): void
}
