import { useHistory } from 'react-router'
import { useLastLocation } from 'react-router-last-location'
import useIoC from '../dependencyInjection/useIoC'
import WebViewParentPlugin from '../plugins/webViewParent/WebViewParentPlugin'
import LogRepository from '../repositories/log/LogRepository'
import HistoryNavigator from './HistoryNavigator'
import Navigator from './Navigator'

export default function useNavigator(): Navigator {
  const history = useHistory()
  const lastLocation = useLastLocation()
  const ioc = useIoC()
  const navigator = new HistoryNavigator(
    ioc.get<WebViewParentPlugin>(WebViewParentPlugin.SYMBOL),
    ioc.get<LogRepository>(LogRepository.SYMBOL),
    history,
    lastLocation ?? undefined,
  )
  return navigator
}
