import InjectableType from '../../dependencyInjection/InjectableType'

interface SimpleBrowserPlugin {
  open(url: string): Promise<void>
  openToS(): Promise<void>
  openPrivacyStatement(): Promise<void>
}

const SimpleBrowserPlugin: InjectableType = {
  SYMBOL: Symbol.for('SimpleBrowserPlugin'),
}

export default SimpleBrowserPlugin
