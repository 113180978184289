import useIoC from '../dependencyInjection/useIoC'
import ViewModel, { ViewModelType } from './ViewModel'
import inject from '../dependencyInjection/inject'
import useNavigator from '../navigation/useNavigator'

export default function useViewModel<T extends ViewModel>(viewModel: ViewModelType | symbol): T {
  const ioc = useIoC()
  const viewModelSymbol = (viewModel as ViewModelType).SYMBOL ?? viewModel
  const viewModelInstance: T = ioc.get(viewModelSymbol)

  // HACK
  inject(viewModelInstance as any, 'navigator', useNavigator())

  return viewModelInstance
}
