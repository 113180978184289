import React from 'react'
import { image } from '../../../utils/resources/resourceUtils'
import CyberMonday2023ViewModel from '../../../viewModels/cybermonday/2023'
import DefaultPromoDetailsViewModel from '../../../viewModels/promo/DefaultPromoDetailsViewModel'
import DefaultPromoIntroViewModel from '../../../viewModels/promo/DefaultPromoIntroViewModel'
import useViewModel from '../../../viewModels/useViewModel'
import DefaultPromoDetailPage from '../../promo/DefaultPromoDetailPage'
import DefaultPromoIntroPage from '../../promo/DefaultPromoIntroPage'
import './2023.scss'

const Intro: React.FC = () => {
  const viewModel: DefaultPromoIntroViewModel = useViewModel(CyberMonday2023ViewModel.SYMBOL_INTRO)

  return (
    <DefaultPromoIntroPage
      id="cyberMonday-2023-intro"
      className="cyberMonday-2023"
      viewModel={viewModel}
      titleImage={image('cyberMonday/2023/header')}
    />
  )
}

const Detail: React.FC = () => {
  const viewModel: DefaultPromoDetailsViewModel =
    useViewModel(CyberMonday2023ViewModel.SYMBOL_DETAIL)

  return (
    <DefaultPromoDetailPage
      id="cyberMonday-2023-detail"
      className="cyberMonday-2023"
      viewModel={viewModel}
      imagePath={image('cyberMonday/2023/header')}
    />
  )
}

const CyberMonday2023 = { Intro, Detail }

export default CyberMonday2023