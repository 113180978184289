import React from 'react'
import { image } from '../../../utils/resources/resourceUtils'
import Promo692023ViewModel from '../../../viewModels/diaDoSexo/2023'
import DefaultPromoDetailsViewModel from '../../../viewModels/promo/DefaultPromoDetailsViewModel'
import DefaultPromoIntroViewModel from '../../../viewModels/promo/DefaultPromoIntroViewModel'
import useViewModel from '../../../viewModels/useViewModel'
import DefaultPromoDetailPage from '../../promo/DefaultPromoDetailPage'
import DefaultPromoIntroPage from '../../promo/DefaultPromoIntroPage'
import './2023.scss'

const Intro: React.FC = () => {
  const viewModel: DefaultPromoIntroViewModel = useViewModel(Promo692023ViewModel.SYMBOL_INTRO)

  return (
    <DefaultPromoIntroPage
      id="promo69-2023-intro"
      className="promo69-2023"
      viewModel={viewModel}
      titleImage={image('diaDoSexo/2023/header')}
    />
  )
}

const Detail: React.FC = () => {
  const viewModel: DefaultPromoDetailsViewModel =
    useViewModel(Promo692023ViewModel.SYMBOL_DETAIL)

  return (
    <DefaultPromoDetailPage
      id="promo69-2023-detail"
      className="promo69-2023"
      viewModel={viewModel}
      imagePath={image('diaDoSexo/2023/header')}
    />
  )
}

const Promo692023 = { Intro, Detail }

export default Promo692023
