import { IonButton, IonContent, IonModal } from '@ionic/react'
import React from 'react'
import useNavigator from '../../navigation/useNavigator'
import useLocalization from '../../plugins/localization/useLocalization'
import './UnavailableContentModal.scss'
import { image } from '../../utils/resources/resourceUtils'

export type UnavailableContentModalProps = {
  isOpen: boolean
  onDidDismiss(arg?: any): void
}

const UnavailableContentModal: React.FC<UnavailableContentModalProps> = ({
  isOpen, onDidDismiss,
}: UnavailableContentModalProps) => {
  const { strings } = useLocalization()
  const { back } = useNavigator()

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false} onDidDismiss={onDidDismiss}>
      <IonContent color="veryLight" className="unavailable-container">
        <div className="unavailable">
          <div className="unavailable__content">
            <img
              alt="nope"
              src={image('unavailable_content')}
              className="unavailable__content__image"
            />
            <span className="unavailable__content__title">
              {strings.title_unavailablecontent}
            </span>
            <IonButton
              color="secondary"
              className="unavailable__content__button"
              onClick={back}
            >
              {strings.button_unavailablecontent}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default UnavailableContentModal
