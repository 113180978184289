import AppErrorCode from './AppErrorCode'

export default class AppError extends Error {
  constructor(public code?: AppErrorCode, message?: string) {
    super(message)
    this.name = AppError.name
  }

  is = (...codes: AppErrorCode[]) => codes.some((c) => c === this.code)
}
