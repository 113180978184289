import Parse from 'parse'

function getEnv(name: string, defaultValue?: string): string {
  const key = `REACT_APP_${name}`
  const env = process.env[key] ?? defaultValue ?? 'ENV VAR undefined'
  return env
}

const ENVIRONMENT: string = getEnv('ENVIRONMENT', 'QA')

function getEnvironmentEnv(name: string): string {
  return getEnv(`${ENVIRONMENT}_${name}`)
}

const Configurations = {
  ENVIRONMENT,
  parse: {
    SERVER_URL: getEnvironmentEnv('PARSE_SERVER_URL'),
    APP_ID: getEnvironmentEnv('PARSE_APPLICATION_ID'),
  },
  email: {
    SUPPORT_ACCOUNT: 'support@ysosapp.zohodesk.com',
  },
  APP_COLOR: '#75bccd',
  APP_SCHEME: 'ysos',
  APP_DOMAIN: 'store',
  APP_URL: 'https://ysosapp.com.br',
  async initialize() {
    const { parse } = this
    Parse.initialize(parse.APP_ID)
    Parse.serverURL = parse.SERVER_URL
  },
}

export default Configurations
